export enum PROMOTION_CATEGORY {
  referralReward = 1,
  dailyCheckIn = 2,
  storePromotions = 4,
  jackpot = 5,
  redEnvelopePromotions = 6,
  couponOffer = 7,
  otherPromotions = 99
}
export enum PROMOTION_STATE {
  shelved = 1,
  notShelved = 0,
}
export enum SETTLE_CYCLE {
  dailyStatement = 0,
  weeklyStatement = 1,
  monthlySettlement = 2
}
export enum PROMOTION_TYPE {
  otherPromotions = 1,
  storePromotions = 2,
  redEnvelopePromotions = 3
}
export enum PRIZE_TYPE {
  grandPrize = 0,
  generalPrize = 1
}