import PagePromotion from "pages/admin/promotion";
import PagePromotionAddCoupon from "pages/admin/promotion/add-coupon";
import PagePromotionAddJackpot from "pages/admin/promotion/add-jackpot";
import PagePromotionAddMission from "pages/admin/promotion/add-mission";
import PagePromotionAddOther from "pages/admin/promotion/add-other";
import PagePromotionAddRedEnvelope from "pages/admin/promotion/add-red-envelope";
import PagePromotionAddStore from "pages/admin/promotion/add-store";
import PagePromotionCoupon from "pages/admin/promotion/coupon";
import PagePromotionCouponSearch from "pages/admin/promotion/coupon-search";
import PagePromotionSearch from "pages/admin/promotion/search";
import PagePromotionEditRecommend from "pages/admin/promotion/edit-recommend";
import PagePromotionEditCoupon from "pages/admin/promotion/edit-coupon";
import PagePromotionEditJackpot from "pages/admin/promotion/edit-jackpot";
import PagePromotionEditMission from "pages/admin/promotion/edit-mission";
import PagePromotionEditOther from "pages/admin/promotion/edit-other";
import PagePromotionEditRedEnvelope from "pages/admin/promotion/edit-red-envelope";
import PagePromotionEditStore from "pages/admin/promotion/edit-store";
import PagePromotionJackpot from "pages/admin/promotion/jackpot";
import PagePromotionJackpotSearch from "pages/admin/promotion/jackpot-search";
import PagePromotionLevel from "pages/admin/promotion/level";
import PagePromotionRebateSearch from "pages/admin/promotion/rebate-search";
import PagePromotionRebate from "pages/admin/promotion/rebate";
import PagePromotionLevelSearch from "pages/admin/promotion/level-search";

export const promotionModule = [
  { path: '/promotion', Component: PagePromotion },
  { path: '/promotion/search', Component: PagePromotionSearch },
  { path: '/promotion/edit-recommend', Component: PagePromotionEditRecommend },
  { path: '/promotion/add-mission', Component: PagePromotionAddMission },
  { path: '/promotion/edit-mission/:id', Component: PagePromotionEditMission },
  { path: '/promotion/add-other', Component: PagePromotionAddOther },
  { path: '/promotion/edit-other/:id', Component: PagePromotionEditOther },
  { path: '/promotion/rebate', Component: PagePromotionRebate },
  { path: '/promotion/rebate-search', Component: PagePromotionRebateSearch },
  { path: '/promotion/level', Component: PagePromotionLevel },
  { path: '/promotion/level-search', Component: PagePromotionLevelSearch },
  { path: '/promotion/add-store', Component: PagePromotionAddStore },
  { path: '/promotion/edit-store/:id', Component: PagePromotionEditStore },
  { path: '/promotion/add-red-envelope', Component: PagePromotionAddRedEnvelope },
  { path: '/promotion/edit-red-envelope/:id', Component: PagePromotionEditRedEnvelope },
  { path: '/promotion/jackpot', Component: PagePromotionJackpot },
  { path: '/promotion/add-jackpot', Component: PagePromotionAddJackpot },
  { path: '/promotion/edit-jackpot/:id', Component: PagePromotionEditJackpot },
  { path: '/promotion/jackpot-search', Component: PagePromotionJackpotSearch },
  { path: '/promotion/coupon', Component: PagePromotionCoupon },
  { path: '/promotion/add-coupon', Component: PagePromotionAddCoupon },
  { path: '/promotion/edit-coupon/:id', Component: PagePromotionEditCoupon },
  { path: '/promotion/coupon-search', Component: PagePromotionCouponSearch },
]