import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Steps, Table, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { TableDrag } from 'components/contact.component';
import { DateSelector, InquiryWindow, LayoutNav, UploadImage } from 'components/layout.component';
import { CKEditorComp } from 'components/promotion.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { PRIZE_TYPE, PROMOTION_STATE } from 'enum/promotion';
import { Game_ISOPEN, Game_STATE, WHETHER } from "enum/state";
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import { toFormatNumber as $f, convertedToPercentage02 as $p2n, enumToOptions, gameNameTransfer, selectFuzzyFilter, specialProviderName, timeL2S, verify } from "utils/common";

// 新增獎池優惠

const PageMain: React.FC = () => {
  const site = useSite();
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState<string>();
  const [isPopupValue, setIsPopupValue] = useState(true);
  const [providerCodes, setProviderCodes] = useState<any[]>([]);
  const [providerIds, setProviderIds] = useState<any[]>([]);
  const [gameIds, setGameIds] = useState<any[]>([]);
  const [game, setGame] = useState<any[]>([]);
  const [whoChange, setWhoChange] = useState<string>('');
  const [isOpenDeleteBankModal, setIsOpenDeleteBankModal] = useState(false);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [date, setDate] = useState<any>([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);

  // 步驟
  const [step, setStep] = useState('1');

  const [settings, setSettings] = useState<any[]>([
    { key: 1, stage: i18n.t('stageOne') },
    { key: 2, stage: i18n.t('stageTwo') },
    { key: 3, stage: i18n.t('stageThree') }
  ]);
  const [rules, setRules] = useState<any[]>([
    { key: 1, PrizeType: PRIZE_TYPE.grandPrize, PrizeRate: 0, PrizeName: '', BalanceLimit: 0 },
    { key: 2, PrizeType: PRIZE_TYPE.generalPrize, PrizeRate: 0, PrizeName: '', BalanceLimit: 0 }
  ]);


  const transferRules = rules.map((item, index) => {
    return {
      ...item,
      item: index === rules.length - 1 ? 'add' : 'item'
    };
  });

  const { data: provider } = $get({
    url: 'api/bonus/provider/list',
  });
  // 遊戲名稱會受到遊戲商id與時間影響
  const { data: _game, isValidating: gameValidating } = $get({
    url: 'api/bonus/provider/game/list',
    params: {
      ProviderIds: providerIds.join(','),
      StartDate: timeL2S(date[0]),
      EndDate: timeL2S(date[1]),
    },
    allow: !!providerIds && providerIds.length > 0,
  });

  // ProviderCodes2Ids
  useEffect(() => {
    if (provider?.Data) {
      const ids = provider?.Data.filter((item: any) => providerCodes.includes(item.Code)).map((item: any) => item.Id);
      setProviderIds(ids);
    }
  }, [providerCodes]);

  useEffect(() => {
    // 需求: 遊戲商改變時 > 剔除GameIds
    if (_game?.Data && !gameValidating && whoChange === 'provider') {
      setGame(_game?.Data || []);
      setWhoChange('');
    } else if ((!providerIds || providerIds.length === 0) && whoChange === 'provider') {
      setGame([]);
      setWhoChange('');

      // 需求: 時間改變且尚未選擇遊戲名稱時 > 剔除GameIds
    } else if (_game?.Data && !gameValidating && whoChange === 'date' && gameIds.length === 0) {
      setGame(_game?.Data);
      setWhoChange('');
    }
  }, [_game, gameValidating]);

  // 剔除GameIds
  useEffect(() => {
    const gameIdList = game.map((item: any) => item.Id);
    const newVal = gameIds.filter((id: any) => gameIdList.includes(id));
    form.setFieldValue('GameIds', newVal);
    setGameIds(newVal);
  }, [game]);

  // 儲存
  const onFinish = (formData: any) => {
    form.validateFields().then(() => {
      const Settings = settings.map((item: any, i: number) => ({
        BetMiniumumAmount: formData[`Settings-${i * 2}`],
        BetMaxiumumAmount: formData[`Settings-${(i * 2) + 1}`],
        LotteryRatio: parseFloat($p2n(formData[`Settings-LotteryRatio-${i}`])),
      }));
      const Rules = rules.slice(0, -1).map((item: any, i: number) => ({
        PrizeOrder: i + 1,
        PrizeType: i === 0 ? PRIZE_TYPE.grandPrize : PRIZE_TYPE.generalPrize,
        PrizeRate: parseFloat($p2n(formData[`Rules-PrizeRate-${i}`])),
        PrizeName: formData[`Rules-PrizeName-${i}`],
        BalanceLimit: formData[`Rules-BalanceLimit-${i}`],
      }));
      $post({
        url: 'api/bonus/jackpot/create',
        send: {
          Status: formData.Status,
          Name: formData.Name,
          StartDate: timeL2S(date[0]),
          EndDate: timeL2S(date[1]),
          IsPopUp: formData.IsPopUp,
          IsRedirect: formData.IsRedirect,
          Position: Number(formData.Position),
          Content: editorContent,
          Photo: imageData[0] ? [imageData[0].url] : [],
          IsValidBetTimes: formData.IsValidBetTimes,
          ProviderCodes: formData.ProviderCodes,
          GameIds: formData.GameIds,
          PrizePoolInitAmount: formData.PrizePoolInitAmount,
          BetContributeRatio: parseFloat($p2n(formData.BetContributeRatio)),
          Settings,
          Rules
        },
        success: () => {
          message.success(i18n.t('addSuccess'));
          navigate('/promotion/jackpot');
        },
        fail: (response: any) => {
          setLoading(false);
          const repeatGamesArray = response?.Data?.map((item: any) => {
            return gameNameTransfer(item.Lang, item.Name)
          })
          const errorMessage = response.Message;
          message.error(
            <>
              {
                repeatGamesArray
                  ? <Row>
                    <Col span={24}>{errorMessage === "GAME_ID_ALREADY_USE" && i18n.t('GAME_ID_ALREADY_USE')}</Col>
                    {
                      repeatGamesArray &&
                      repeatGamesArray?.map((repeatGame: string, i: number) => {
                        return (
                          <Col span={24} key={i}>{repeatGame}</Col>
                        )
                      })
                    }
                  </Row>
                  : RESPONSE_CODE_BONUS[response.Message as keyof typeof RESPONSE_CODE_BONUS]
              }
            </>
          );
        },
        showMessage: false
      }, setLoading)
    }).catch((err) => {
    });
  }

  const updateRules = (val: string, index: number) => {
    let _rules: any = rules;
    if (val === 'add') {
      _rules.push({
        key: Date.now(),
        PrizeType: PRIZE_TYPE.generalPrize,
        PrizeRate: 0.00,
        PrizeName: '',
        BalanceLimit: 0
      })

    } else {
      const formData = form.getFieldsValue();
      _rules.splice(index, 1);
      _rules.forEach((item: any, i: number) => {
        const actualIndex = i < index ? i : i + 1;
        form.setFieldValue(`Rules-PrizeRate-${i}`, formData[`Rules-PrizeRate-${actualIndex}`]);
        form.setFieldValue(`Rules-PrizeName-${i}`, formData[`Rules-PrizeName-${actualIndex}`]);
        form.setFieldValue(`Rules-BalanceLimit-${i}`, formData[`Rules-BalanceLimit-${actualIndex}`]);
      });

    }
    setRules([..._rules])
  }

  // 規則互換
  const swapRules = (active: any, over: any) => {
    const formData = form.getFieldsValue();
    const activeIndex = rules.findIndex(item => item.key === active.id);
    const overIndex = rules.findIndex(item => item.key === over.id);
    const temp = [
      formData[`Rules-PrizeRate-${activeIndex}`],
      formData[`Rules-PrizeName-${activeIndex}`],
      formData[`Rules-BalanceLimit-${activeIndex}`]
    ];

    form.setFieldValue(`Rules-PrizeRate-${activeIndex}`, formData[`Rules-PrizeRate-${overIndex}`]);
    form.setFieldValue(`Rules-PrizeName-${activeIndex}`, formData[`Rules-PrizeName-${overIndex}`]);
    form.setFieldValue(`Rules-BalanceLimit-${activeIndex}`, formData[`Rules-BalanceLimit-${overIndex}`]);

    form.setFieldValue(`Rules-PrizeRate-${overIndex}`, temp[0]);
    form.setFieldValue(`Rules-PrizeName-${overIndex}`, temp[1]);
    form.setFieldValue(`Rules-BalanceLimit-${overIndex}`, temp[2]);
  }

  const updateStep = (stepIndex: string) => {
    if (stepIndex === '2') {
      form.validateFields([
        'Name',
        'IsPopUp', 'IsRedirect',
        'ProviderCodes', 'GameIds',
        'PrizePoolInitAmount',
        'BetContributeRatio',
        'IsValidBetTimes',
        'Settings-0', 'Settings-1', 'Settings-LotteryRatio-0',
        'Settings-2', 'Settings-3', 'Settings-LotteryRatio-1',
        'Settings-4', 'Settings-5', 'Settings-LotteryRatio-2',
        'Status', 'Position', 'PromotionTime',
        'Photo',
        'InternalContent'
      ])
        .then(() => {
          setStep(stepIndex);
        })
        .catch((err) => {
        });
    } else {
      setStep(stepIndex);
    }
  }

  // 聖經: 不得小於上層 大於下層 最嚴謹
  const validator: any = async (d: any, val: number, i: number, min: number, equal: boolean) => {
    const _val: number = Number(val);
    const upperInputValue: number = Number(form.getFieldValue(`Settings-${i - 1}`));
    const downerInputValue: number = Number(form.getFieldValue(`Settings-${i + 1}`));

    const msg = (upperInputValue || 0) > (min || 0) ? upperInputValue : min;

    // 需大於等於min
    if (equal && _val < min) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')}${$f(msg, 0)}`}</p>);
    // 需大於min
    else if (!equal && _val <= min) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThan')}${$f(msg, 0)}`}</p>);
    // 需大於上層
    else if (_val <= upperInputValue && (!isNaN(upperInputValue) && upperInputValue > 0)) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThan')}${$f(upperInputValue, 0)}`}</p>);
    // 需小於下層
    else if (_val >= downerInputValue && (!isNaN(downerInputValue) && downerInputValue > 0)) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThan')}${$f(downerInputValue, 0)}`}</p>);

    else return Promise.resolve();
  }

  const validator2: any = async (d: any, val: number, equal: boolean) => {
    const _val: number = Number(val);
    const min: number = 0.01;
    const max: number = 100;

    // 需大於等於min
    if (_val < min) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')}${$f(min, 2)}`}</p>);
    // 需小於等於max
    else if (equal && _val > max) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThanOrEqualTo')}${$f(max, 0)}`}</p>);
    // 需小於max
    else if (!equal && _val >= max) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThan')}${$f(max, 0)}`}</p>);

    else return Promise.resolve();
  }

  const validateOther: any = async (i: number, name: string) => {
    form.validateFields([`Settings-${i - 1}`, `Settings-${i + 1}`]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
          DistributionMethod: 0
        }}>
          <Steps
            className='pb-1'
            current={Number(step) - 1}
            items={[
              { title: 'Step 1' },
              { title: 'Step 2' },
              { title: 'Done' }
            ]}
          />

          {/* 步驟一 */}
          <Row gutter={[12, 12]} style={{ display: step === '1' ? 'flex' : 'none' }}>

            {/* 名稱與確認 */}
            <Col span={24}>
              <Row justify="space-between" align="top">
                <Col>
                  <Form.Item name="Name" label={i18n.t('jackpotName')}
                    rules={[{ required: true, message: `${i18n.t('required')}` },
                    { type: 'string', max: 100, message: `${i18n.t('promotionActivityNameLimitedTo100Characters')}` }]}
                  >
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                {/* 下一步 */}
                <Col>
                  <Space size="middle">
                    <Button onClick={() => navigate('/promotion/jackpot')}>{i18n.t('cancel')}</Button>
                    <Button onClick={() => updateStep('2')} type="primary">{i18n.t('nextStep')}</Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            {/* 彈窗 */}
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="IsPopUp" className="w-12" label={i18n.t('popupDisplay')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      onChange={e => {
                        setIsPopupValue(e === 0);
                        form.setFieldValue('redirectToPromotionPage', 0);
                      }}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="IsRedirect" className="w-12" label={i18n.t('redirectToPromotionPage')}
                    rules={[{ required: !isPopupValue, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled={isPopupValue}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 遊戲商 / 遊戲名稱 */}
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="ProviderCodes" className="w-12" label={
                    <div>
                      <div>{`${i18n.t('applicable')}${i18n.t('gameProvider')}`}</div>
                      <div className="color-03">{`(${i18n.t('selectableMultiple')})`}</div>
                    </div>
                  } rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={i18n.t('pleaseSelect')}
                      filterOption={selectFuzzyFilter}
                      onChange={e => {
                        setProviderCodes(e);
                        setWhoChange('provider');
                      }}
                      options={
                        (provider?.Data || []).map((option: any) => ({
                          value: option.Code,
                          label: `${specialProviderName(option.Name, site.data.SiteName, option.Code)}`
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="GameIds" className="w-12" label={
                    <div>
                      <div>{`${i18n.t('gameName')}`}</div>
                      <div className="color-03">{`(${i18n.t('selectableMultiple')})`}</div>
                    </div>
                  } rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={i18n.t('pleaseSelect')}
                      filterOption={selectFuzzyFilter}
                      onChange={setGameIds}
                      disabled={!providerCodes || providerCodes.length < 1}
                      options={
                        game
                          .filter((option: any) => option.Status === Game_STATE.enabled && option.IsOpen === Game_ISOPEN.shelved)
                          .map((option: any) => ({
                            value: option.Id,
                            label: `${gameNameTransfer(option.Lang, option.Name)}`
                          }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 獎池底金 */}
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="PrizePoolInitAmount" label={i18n.t('jackpotAmount')}
                    rules={verify({ point: 0, max: 9999999, isShowCompareNumber: true })}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 投注貢獻比例 */}
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="BetContributeRatio" label={i18n.t('bettingRatio')} rules={verify({ min: 0.01, max: 100, isShowCompareNumber: true })}>
                    <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 洗碼倍率 */}
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="IsValidBetTimes" className="w-12" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 1, label: `${i18n.t('yes')}(1${i18n.t('multiplier')})` },
                        { value: 0, label: `${i18n.t('none')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 階段表 */}
            <Col span={12}>
              <Table
                size="small"
                bordered
                dataSource={settings}
                columns={[
                  {
                    dataIndex: 'stage',
                    width: 50,
                    render: (val: any, record: any, i: number) => <>
                      <span className="require">*</span>{val}
                    </>,
                  },
                  {
                    title: <><span className="require">*</span>{i18n.t('betAmountLowerUpperLimit')}</>,
                    width: 200,
                    render: (_: any, record: any, i: number) => (
                      <Row justify={'center'} align={'middle'}>
                        <Col span={11}>
                          <Form.Item name={`Settings-${i * 2}`} rules={[
                            ...verify({ point: 4, max: 999999999 }), { validator: (d, val) => validator(d, val, i * 2, i * 2 === 0 ? 0 : 0.0001, i * 2 === 0) }
                          ]}>
                            <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`}
                              onChange={() => validateOther(i * 2)} />
                          </Form.Item>
                        </Col>
                        <Col span={2} className='text-center'>
                          ~
                        </Col>
                        <Col span={11}>
                          <Form.Item name={`Settings-${(i * 2) + 1}`} rules={[
                            ...verify({ point: 4, max: 999999999 }), { validator: (d, val) => validator(d, val, (i * 2) + 1, 0.0001, false) }
                          ]}>
                            <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`}
                              onChange={() => validateOther((i * 2) + 1)} />
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  },
                  {
                    title: <><span className="require">*</span>{i18n.t('oddsOfPrize')}</>,
                    width: 100,
                    render: (_: any, record: any, i: number) => (
                      <Form.Item name={`Settings-LotteryRatio-${i}`} rules={verify({ max: 100, isShowCompareNumber: true })}>
                        <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} />
                      </Form.Item>
                    )
                  }
                ]}
                pagination={false}
              />
            </Col>
            {/* 派發方式 */}
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="DistributionMethod" className="w-12" label={i18n.t('distributionMethod')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled={true}
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: `${i18n.t('directClaim')}` },
                        { value: 1, label: `${i18n.t('manualDispatch')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="Status" className="w-12" label={i18n.t('status')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(PROMOTION_STATE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="Position" label={i18n.t('sort')}
                    rules={[...verify({ point: 0 }), { max: 99, type: 'number' }]}>
                    <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="PromotionTime" label={`${i18n.t('promotionStartTime')} - ${i18n.t('promotionEndTime')}`}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <DateSelector form={form} name="PromotionTime" date={date} setDate={(e: any) => {
                      setDate(e);
                      setWhoChange('date');
                    }}
                      displayCom={['Picker']} rangeLimit={DATE_RANGE_LIMIT.future} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* 上傳封面圖片 */}
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="Photo" label={i18n.t('image')} 
                    rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                    <UploadImage
                      name="Photo"
                      url={'/upload/images/public/bonus'}
                      imageData={imageData}
                      setImageData={setImageData}
                      form={form}
                      w={1920}
                      h={560}
                    />
                  </Form.Item>
                  <div className="color-03 size-12">
                    {i18n.t('onlyJpgOrPngFiles')}
                    <span className="require">{i18n.t('imageMobileVersionWidth1920pxHeight560px')}</span>
                    {i18n.t('allowedAndSizeNotExceed500kb')}
                  </div>
                </Col>
              </Row>
            </Col>
            {/* 內文編輯器 */}
            <Col span={24}>
              <Form.Item name="InternalContent" label={i18n.t('internalContent')} rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'InternalContent'} image />
              </Form.Item>
            </Col>

          </Row>

          {/* 步驟二 */}
          <Row gutter={[12, 12]} style={{ display: step === '2' ? 'flex' : 'none' }}>
            {/* 名稱與確認 */}
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                </Col>
                <Col>
                  <Space size="middle">
                    <Button onClick={() => updateStep('1')}>{i18n.t('prevStep')}</Button>
                    <Button onClick={() => setIsOpenDeleteBankModal(true)} type="primary">{i18n.t('confirm')}</Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <TableDrag
                size="small"
                bordered
                pagination={false}
                disabledDragIndex={0}
                callback={swapRules}
                dataSource={transferRules}
                setDataSource={setRules}
                columns={[
                  {
                    dataIndex: 'PrizeOrder',
                    width: 100,
                    title: i18n.t('prizeSort'),
                    render: (_: any, record: any, i: number) => i + 1
                  },
                  {
                    dataIndex: 'PrizeType',
                    title: i18n.t('prizeType'),
                    width: 150,
                    render: (val: any, record: any, i: number) => i18n.t(PRIZE_TYPE[val])
                  },
                  {
                    dataIndex: 'PrizeRate',
                    title: <><span className="require">*</span>{i18n.t('prizeRatio')}</>,
                    width: 200,
                    render: (_: any, { item }: any, i: number) => (
                      item === 'item'
                        ? <Form.Item name={`Rules-PrizeRate-${i}`} key={`Rules-PrizeRate-${i}`} rules={[
                          ...verify({ point: 2 }), { validator: (d, val) => validator2(d, val, i === 0) }
                        ]}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} />
                        </Form.Item>
                        : ''
                    )
                  },
                  {
                    dataIndex: 'PrizeName',
                    title: <><span className="require">*</span>{i18n.t('prizeName')}</>,
                    width: 200,
                    render: (_: any, { item }: any, i: number) => (
                      item === "item"
                        ? <Form.Item name={`Rules-PrizeName-${i}`} rules={[
                          { required: true, message: `${i18n.t('required')}` },
                          { type: 'string', max: 10, message: `${i18n.t('exceedsCharacterLimit10')}` }
                        ]}>
                          <Input className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                        : ''
                    )
                  },
                  {
                    dataIndex: 'BalanceLimit',
                    width: 200,
                    title: () => (
                      <Tooltip placement="top" className="size-12" title={i18n.t('lowerThanSetValue')}>
                        <Row align="middle" gutter={3}>
                          <Col className="size-12"><span className="require">*</span>{i18n.t('balanceLimit')}</Col>
                          <Col><InfoCircleFilled /></Col>
                        </Row>
                      </Tooltip>
                    ),
                    render: (_: any, { item }: any, i: number) => (
                      item === 'item' ? <Form.Item name={`Rules-BalanceLimit-${i}`} rules={verify({ point: 4 })}>
                        <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} addonBefore={'≦'} />
                      </Form.Item> : ''
                    )
                  },
                  {
                    dataIndex: 'operation',
                    width: 120,
                    title: i18n.t('operation'),
                    render: (_: any, record: any, i: number) => <>
                      {i === rules.length - 1 && i < 99
                        && <Button type="link" onClick={() => updateRules('add', i)}>{i18n.t('add')}</Button>}
                      {i !== rules.length - 1 && i !== 0
                        && <Button type="link" onClick={() => updateRules('delete', i)}>{i18n.t('delete')}</Button>}
                    </>,
                  },
                ]}
              />
            </Col>
          </Row>

          <InquiryWindow isOpen={isOpenDeleteBankModal} close={() => setIsOpenDeleteBankModal(false)}
            msg={`${i18n.t('cantEditAfterPrizeing')}${i18n.t('confirm')}`} action={form.submit} />

        </Form>
      </Content >
    </div >
  );
};

export default PageMain;