import { Breadcrumb, Button, Checkbox, Col, ColorPicker, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Spin, Table, Tag, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import hintIcon from 'assets/image/icon-12.png';
import { agenti18n } from 'constants/permission';
import { RESPONSE_CODE, RESPONSE_CODE_CHANGELINE, RESPONSE_CODE_TAG } from 'constants/response';
import dayjs from 'dayjs';
import { AGENT_LOG } from 'enum/cashlog';
import { CHECKBOX_DISPLAY, ENABLE, SETTLEMENT } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useIcon from 'hooks/icon.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { $get, $post } from 'services';
import { setSearchHistory } from 'slice/searchSlice';
import { RootType } from 'store/configureStore';
import * as common from 'utils/common';
import { numColor03 as $c, toFormatNumber as $f, verify } from 'utils/common';
import { LayoutPagination, LayoutTextArea } from './layout.component';

// 總代列表
export const ViewAgentSaList = ({
  refs,
  saList,
  columns,
  isValidating,
  pageSize,
  pageIndex,
  setPage
}: any) => {
  const dispatch = useDispatch();
  useImperativeHandle(refs, () => ({

    refresh: () => {
      resetSa();
    },

    // 子代按鈕
    openAgent: (record: any) => {
      const AGsId = record.AGsId.split('|');
      const parentId = parseInt(AGsId[AGsId.length - 2]);

      if (record.Level === 1) {
        setSaId(parentId)
        setLv0Parameter({ ...lv0Parameter, AgentId: parentId })
      }
      expand(record, true)

      refAgentLevelBread.current?.startAskBread(record);
    },

  }));

  // 當前打開
  const [currentExpanded, setCurrentExpanded] = useState<any>([]);

  // 點開
  const expand = (record: any, quick?: any) => {
    const AGsId = record.AGsId.split('|');
    const parentParentId = parseInt(AGsId[AGsId.length - 3]);
    const parentId = parseInt(AGsId[AGsId.length - 2]);

    if (record.Level === 0) {
      setLv0Parameter({ ...lv0Parameter, AgentId: record.Id })
      setCurrentExpanded([record.Id])
    } else {
      setSaId(record.SAId)
      setLv1Parameter({ ...lv1Parameter, AgentId: parentParentId })
      setLv2Parameter({ ...lv2Parameter, AgentId: parentId })
      setLv3Parameter({ ...lv3Parameter, AgentId: record.Id })
    }

    if (!quick) refAgentLevelBread.current?.updateStream(true, record);
  }

  // 收合
  const backto = (record: any) => {
    if (!record) return

    const AGsId = record.AGsId.split('|');
    const parentParentParentId = parseInt(AGsId[AGsId.length - 4]);
    const parentParentId = parseInt(AGsId[AGsId.length - 3]);
    const parentId = parseInt(AGsId[AGsId.length - 2]);

    if (record.Level === 0) {
      resetSa()
    } else if (record.Level === 1) {
      setLv0Parameter({ ...lv0Parameter, AgentId: parentId })
      setLv1Parameter({ ...lv1Parameter, AgentId: 0 })
      setLv2Parameter({ ...lv2Parameter, AgentId: 0 })
      setLv3Parameter({ ...lv3Parameter, AgentId: 0 })
      setCurrentExpanded([parentId])
    } else if (record.Level === 2) {
      setSaId(parentParentId)
      setLv0Parameter({ ...lv0Parameter, AgentId: parentParentId })
      setLv1Parameter({ ...lv1Parameter, AgentId: 0 })
      setLv2Parameter({ ...lv2Parameter, AgentId: parentParentId })
      setLv3Parameter({ ...lv3Parameter, AgentId: parentId })
    } else {
      setSaId(0)
      setLv1Parameter({ ...lv1Parameter, AgentId: parentParentParentId })
      setLv2Parameter({ ...lv2Parameter, AgentId: parentParentId })
      setLv3Parameter({ ...lv3Parameter, AgentId: parentId })
    }

    refAgentLevelBread.current?.updateStream(false, record);
  }

  const clickSetPage = (e: any, level: any) => {
    if (level === 1) setLv0Parameter({ ...lv0Parameter, PageIndex: e.current })
    else {
      setLv3Parameter({ ...lv3Parameter, PageIndex: e.current })
      setPage(e);
    }
  }

  const resetSa = () => {
    setSaId(0)
    setLv0Parameter({ ...lv0Parameter, AgentId: 0 })
    setLv1Parameter({ ...lv1Parameter, AgentId: 0 })
    setLv2Parameter({ ...lv2Parameter, AgentId: 0 })
    setLv3Parameter({ ...lv3Parameter, AgentId: 0 })
    setCurrentExpanded([])
    // dispatch(setSearchHistory({ saExpand }));
    refAgentLevelBread.current?.setBreadList([]);
  }

  const [saId, setSaId] = useState(0);
  const { data: sa } = $get({
    url: 'api/agent/sa/list',
    params: {
      AgentId: saId,
      PageSize: 1,
      PageIndex: 1,
      CreateDateStartTime: '2022-01-01 00:00:00',
      CreateDateEndTime: '2036-12-01 00:00:00'
    },
    allow: !!saId
  })
  const [lv0Parameter, setLv0Parameter] = useState({
    AgentId: 0,
    PageSize: pageSize,
    PageIndex: 1,
  });
  const { data: lv0Agent, isValidating: lv0AgentValidating } = $get({
    url: 'api/agent/ag/list',
    params: lv0Parameter,
    allow: !!lv0Parameter.AgentId
  })
  const [lv1Parameter, setLv1Parameter] = useState({
    AgentId: 0,
    PageSize: 9999,
    PageIndex: 1,
  });
  const { data: lv1Agent, isValidating: lv1AgentValidating } = $get({
    url: 'api/agent/ag/list',
    params: lv1Parameter,
    allow: !!lv1Parameter.AgentId
  })
  const [lv2Parameter, setLv2Parameter] = useState({
    AgentId: 0,
    PageSize: 9999,
    PageIndex: 1,
  });
  const { data: lv2Agent, isValidating: lv2AgentValidating } = $get({
    url: 'api/agent/ag/list',
    params: lv2Parameter,
    allow: !!lv2Parameter.AgentId
  })
  const [lv3Parameter, setLv3Parameter] = useState({
    AgentId: 0,
    PageSize: pageSize,
    PageIndex: pageIndex || 1,
  });
  const { data: lv3Agent, isValidating: lv3AgentValidating } = $get({
    url: 'api/agent/ag/list',
    params: lv3Parameter,
    allow: !!lv3Parameter.AgentId
  })
  const rowExpandable = (record: Agent) => (record?.DownlineAgentCount || 0) + (record?.InDownlineAgentCount || 0) > 0
  const showTotal = (total: any) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`

  // 記憶展開
  const { saExpand } = useSelector((state: RootType) => state.Search);

  const initialized = useRef(false)
  useEffect(() => {
    const jsonSaExpand = JSON.parse(JSON.stringify(saExpand));
    if (saExpand && jsonSaExpand.length > 0 && !initialized.current) {

      initialized.current = true;

      const record = jsonSaExpand[jsonSaExpand.length - 1]

      const AGsId = record.AGsId.split('|');
      const parentId = parseInt(AGsId[AGsId.length - 2]);


      if (record.Level === 1) {
        setSaId(parentId)
        setLv0Parameter({ ...lv0Parameter, AgentId: parentId })
      }
      expand(record)

      refAgentLevelBread.current?.setBreadList(jsonSaExpand);
    }
  }, []);

  const refAgentLevelBread = useRef<any>();

  return (
    <>
      {/* 代層麵包 */}
      <AgentLevelBread refs={refAgentLevelBread} lastLevel={lv2Agent?.Data}
        resetSa={resetSa} backto={backto} />

      {/* 總代模式 */}
      {!lv1Parameter.AgentId &&
        <>
          <Table
            size='middle'
            className='table-h-full'
            columns={columns.filter((item: any) => item.show) as any}
            loading={isValidating}
            dataSource={
              sa
                ? sa.Data.map((item: any) => ({ ...item, key: item.Id }))
                : !!lv2Parameter.AgentId && saList.Data.length > 0
                  ? [saList.Data.map((item: any) => ({ ...item, key: item.Id })).find((item: any) => item.Id === lv0Parameter.AgentId)]
                  : saList?.Data.map((item: any) => ({ ...item, key: item.Id }))
            }
            pagination={!lv2Parameter.AgentId && {
              total: saList?.TotalRecord,
              showSizeChanger: true,
              showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
              current: pageIndex
            }}
            onChange={e => clickSetPage(e, 0)}
            expandable={{
              expandedRowKeys: [lv0Parameter.AgentId],
              rowExpandable,
              onExpand: (open: boolean, record: any) => {
                if (open) expand(record)
                else backto(record)
              },

              // 一代一代
              expandedRowRender: () => (!lv2Parameter.AgentId &&
                <Table
                  size='middle'
                  className='table-h-full'
                  showHeader={false}
                  columns={columns.filter((item: any) => item.show) as any}
                  loading={lv0AgentValidating}
                  dataSource={lv0Agent?.Data.map((item: any) => ({ ...item, key: item.Id }))}
                  pagination={{
                    total: lv0Agent?.TotalRecord,
                    showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                    showSizeChanger: true,
                  }}
                  onChange={e => clickSetPage(e, 1)}
                  expandable={{
                    expandedRowKeys: currentExpanded,
                    expandedRowRender: () => <></>,
                    rowExpandable,
                    onExpand: (open: boolean, record: any) => {
                      if (open) expand(record)
                      else backto(record)
                    },
                  }}
                />)

            }} />
        </>
      }

      {/* 獨立模式 */}
      {/* 第一層 */}
      {!!lv1Parameter.AgentId && <><Table
        size='middle'
        className='table-h-full'
        columns={columns.filter((item: any) => item.show) as any}
        loading={lv1AgentValidating}
        dataSource={
          lv1Agent?.Data
            ? [lv1Agent.Data.map((item: any) => ({ ...item, key: item.Id })).find((item: any) => item.Id === lv2Parameter.AgentId)]
            : []
        }
        pagination={false}
        expandable={{
          expandedRowKeys: [lv2Parameter.AgentId],
          expandedRowRender: () => <></>,
          rowExpandable,
          onExpand: (open: boolean, record: any) => {
            if (open) expand(record)
            else backto(record)
          },
        }}
      /></>}

      {/* 第二層 */}
      {!!lv2Parameter.AgentId && <><Table
        size='middle'
        className='table-h-full'
        showHeader={!lv0Parameter.AgentId && !lv1Parameter.AgentId}
        columns={columns.filter((item: any) => item.show) as any}
        loading={lv2AgentValidating}
        dataSource={
          lv2Agent?.Data
            ? [lv2Agent.Data.map((item: any) => ({ ...item, key: item.Id })).find((item: any) => item.Id === lv3Parameter.AgentId)]
            : []
        }
        pagination={false}
        expandable={{
          expandedRowKeys: [lv3Parameter.AgentId],
          expandedRowRender: () => <></>,
          rowExpandable,
          onExpand: (open: boolean, record: any) => {
            if (open) expand(record)
            else backto(record)
          },
        }}
      /></>}

      {/* 第三層 */}
      {!!lv3Parameter.AgentId &&
        <>
          <Table
            size='middle'
            className='table-h-full'
            showHeader={!lv2Parameter.AgentId}
            columns={columns.filter((item: any) => item.show) as any}
            loading={lv3AgentValidating}
            dataSource={lv3Agent?.Data?.map((item: any) => ({ ...item, key: item.Id }))}
            pagination={{
              total: lv3Agent?.TotalRecord,
              showSizeChanger: true,
              showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
              current: pageIndex
            }}
            onChange={e => clickSetPage(e, 2)}
            expandable={{
              expandedRowKeys: [],
              expandedRowRender: () => <></>,
              rowExpandable,
              onExpand: (open: boolean, record: any) => {
                if (open) expand(record)
                else backto(record)
              },
            }} />
        </>
      }
    </>
  )
}

export const AgentLevelBread = ({ refs, resetSa, backto }: any) => {
  const dispatch = useDispatch();
  useImperativeHandle(refs, () => ({

    // 給快捷用的
    startAskBread: startAskBread,
    // 給按鈕用的
    updateStream: updateStream,
    setBreadList: setBreadList

  }));

  // 麵包陣列 同代理陣列
  const [breadList, setBreadList] = useState<any>([]);
  const startAskBread = (record: any) => {
    setAgentId(0)

    if (record) {
      setBreadList([record])
      setAgentId(record.AGId)
    }
  }

  const [agentId, setAgentId] = useState(0);
  const { data: agentOne, isValidating } = $get({
    url: 'api/agent/one',
    params: { Id: agentId },
    allow: !!agentId
  })
  useEffect(() => {
    if (agentOne) {
      breadList.unshift(agentOne.Data)
      setBreadList([...breadList])

      if (agentOne.Data.AGId !== agentOne.Data.Id) setAgentId(agentOne.Data.AGId)
      else {
        dispatch(setSearchHistory({ saExpand: breadList }));
      }
    }
  }, [agentOne]);

  const updateStream = (open: boolean, record: any) => {
    if (open) {
      if (record.Level === 0) breadList.length = 0
      breadList.push(record)
    }
    else {
      const index = breadList.findIndex((item: any) => item.Id === record.Id);
      if (index !== -1) breadList.length = index;
    }
    setBreadList([...breadList]);
    dispatch(setSearchHistory({ saExpand: breadList }));
  }

  const breadcrumbItems = new Map();
  breadcrumbItems.set('/', [
    { title: <a onClick={() => resetSa()}>{i18n.t('generalAgentList')}</a> },
    ...breadList.map((item: any, i: number) => {
      const word = common.agentLevelWord(item.Level);
      return {
        title: <a onClick={() => backto(breadList[i + 1])}>{`${word}／${item.Account}`}</a>
      }
    })
  ]);

  return (
    <Spin spinning={isValidating}>
      <Breadcrumb separator=">" items={breadcrumbItems.get('/')} />
    </Spin>
  )
}

// 代理權限
export const SelectAgentPermission = ({ agent, permissions, setPermissions, confirm, disabled }: any) => {

  const { data: $s, isCashVersion: $sc, init: $init } = useSite();

  useEffect(() => {
    if (agent && $init) {
      updateCheckStateOne();
    }
  }, [agent, $init]);

  const allList = agenti18n($sc, $s);

  const [form] = useForm();

  const [menuCheckState, setMenuCheckState] = useState<any>({});
  const updateCheckState = (record: any, level: any, checked: any, itemKey?: any) => {
    const fieldsValue: any = {};

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    allList.forEach((page: any) => {
      managementSum = 0;

      if (page.MenuName === record.MenuName) {
        let readCount = false;
        menuTotal = menuTotal + page.child.length

        page.child.forEach((item: any, i: number) => {
          // 計算勾選數量
          if (
            (level === 'menu' && checked) ||
            (page.name === record.name && (
              (level === 'management' && checked) ||
              (level === 'operation' && form.getFieldValue(`cb-${item.key}`))
            ))
          ) {
            managementSum++
            menuSum++
            if (i === 0) readCount = true;
          }
          else if (
            page.name !== record.name && (level === 'management' || level === 'operation') && form.getFieldValue(`cb-${item.key}`)
          ) {
            menuSum++
          }
          // 改變勾選狀態
          if ((level === 'management' && page.name === record.name) || level === 'menu') {
            fieldsValue[`cb-${item.key}`] = checked;

            const index = permissions.indexOf(item.key);
            if (!checked && index > -1) permissions.splice(index, 1);
            else if (checked && index === -1) permissions.push(item.key);
          } else if (level === 'operation' && item.key === itemKey) {
            const index = permissions.indexOf(item.key);
            if (!checked && index > -1) permissions.splice(index, 1);
            else if (checked && index === -1) permissions.push(item.key);
          }
          // 查看自動勾起
          if ((level === 'operation' && page.key === record.key) && checked) {
            if (page.child.length === 2 && !fieldsValue[`cb-${page.child[0].key}`] && !readCount) {
              managementSum++
              menuSum++
              readCount = true;
            }
            fieldsValue[`cb-${page.child[0].key}`] = true;

            const index = permissions.indexOf(page.child[0].key);
            if (checked && index === -1) permissions.push(page.child[0].key);
          }
        })

        if (((level === 'management' || level === 'operation') && page.name === record.name) || level === 'menu') {
          if (managementSum === 0) menuCheckState[page.name] = CHECKBOX_DISPLAY.empty
          else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.square
          else if (managementSum === page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.tick
        }
      }
    })

    if (menuSum === 0) menuCheckState[record.MenuName] = CHECKBOX_DISPLAY.empty;
    else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[record.MenuName] = CHECKBOX_DISPLAY.square;
    else if (menuSum === menuTotal) menuCheckState[record.MenuName] = CHECKBOX_DISPLAY.tick;
    form.setFieldsValue({ ...fieldsValue });
    setMenuCheckState({ ...menuCheckState });
    setPermissions(permissions);
  };
  const updateCheckStateOne = () => {
    const fieldsValue: any = {};
    const permissionCode = JSON.parse(agent.Permission);
    const permissions: any = [];

    let menuTotal = 0;
    let menuSum = 0;
    let managementSum = 0;

    let menuName = allList[0].MenuName;

    allList.forEach((page: any, i: number) => {
      managementSum = 0;
      menuTotal = menuTotal + page.child.length;

      // management
      page.child.forEach((item: any) => {
        // 計算勾選數量
        if (permissionCode.includes(item.key)) {
          managementSum++
          menuSum++
          fieldsValue[`cb-${item.key}`] = true;
          permissions.push(item.key);
        }
      })
      if (managementSum === 0) menuCheckState[page.name] = CHECKBOX_DISPLAY.empty
      else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.square
      else if (managementSum === page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.tick

      // menu
      if ((allList[i + 1]?.MenuName !== menuName) || !allList[i + 1]) {
        if (menuSum === 0) menuCheckState[menuName] = CHECKBOX_DISPLAY.empty
        else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[menuName] = CHECKBOX_DISPLAY.square
        else if (menuSum === menuTotal) menuCheckState[menuName] = CHECKBOX_DISPLAY.tick

        menuName = allList[i + 1]?.MenuName;
        menuSum = 0;
        menuTotal = 0;
      }
    })
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })
    setPermissions(permissions)
  };

  return (
    <Form form={form}>
      <Table
        className="w-full"
        size="middle"
        bordered
        dataSource={allList}
        columns={[
          {
            key: 1,
            title: <><span className="require">*</span>{i18n.t('page')}</>,
            width: '25%',
            onCell: (record, index) => ({ rowSpan: record.rowSpan || 0 }),
            render: (_, record: any) =>
              <Checkbox
                indeterminate={menuCheckState[record.MenuName] === CHECKBOX_DISPLAY.square}
                checked={menuCheckState[record.MenuName] === CHECKBOX_DISPLAY.tick}
                onChange={e => updateCheckState(record, 'menu', e.target.checked)}
                disabled={confirm || disabled}
              >{record.MenuName}</Checkbox>,
          },
          {
            key: 2,
            className: 'size-12',
            width: '25%',
            render: (_, record: any) =>
              <Checkbox
                indeterminate={menuCheckState[record.name] === CHECKBOX_DISPLAY.square}
                checked={menuCheckState[record.name] === CHECKBOX_DISPLAY.tick}
                onChange={e => updateCheckState(record, 'management', e.target.checked)}
                disabled={confirm || disabled}
              >{record.name || record.ManagementName}</Checkbox>
          },
          {
            key: 3,
            title: i18n.t('permissions'),
            width: '50%',
            render: (_, record) => record.child.map((item: any) =>
              <Form.Item key={item.key} name={`cb-${item.key}`} valuePropName="checked" style={{ display: 'inline-block' }}>
                <Checkbox
                  onChange={e => updateCheckState(record, 'operation', e.target.checked, item.key)}
                  disabled={confirm || disabled}
                >{`${item.name}`}</Checkbox>
              </Form.Item>
            )
          },
        ]}
        pagination={false}
      />
    </Form>
  )
}

// 結算週期下拉表
export const SelectCycleDate = ({ setPeriod, id, mode }: any) => {
  const { data: commissionList } = $get({
    url: 'api/agent/report/commission/period/list',
    params: { AgentId: id },
    allow: mode === SETTLEMENT.revenue
  })
  const { data: revenueshareList } = $get({
    url: 'api/agent/report/revenueshare/period/list',
    params: { AgentId: id },
    allow: mode === SETTLEMENT.revenueshare
  })

  return (
    <Select
      placeholder={i18n.t('pleaseSelectDate')}
      className="w-full"
      options={(commissionList?.Data || revenueshareList?.Data)?.map((item: any) => ({ value: item.Period }))}
      onChange={setPeriod}
    />
  )
}

// 帳目
export const PopupAccounting = ({ isOpen, close, AgentAccount }: any) => {
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState([
    dayjs().add(-1, 'month').startOf('day').format('YYYY-MM-DD 00:00:00'),
    dayjs().format('YYYY-MM-DD 23:59:59')
  ]);
  const { data: cashlog, isValidating } = $get({
    url: 'api/agent/cashlog/list',
    params: {
      Account: AgentAccount,
      StartDate: common.timeL2S(date[0]),
      EndDate: common.timeL2S(date[1]),
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: !!AgentAccount
  })

  return (
    <Modal
      title={`${i18n.t('accounting')} (${i18n.t('last30Days')})`}
      open={isOpen}
      centered
      onCancel={close}
      footer={false}
      width={1000}
    >
      <Table
        loading={isValidating}
        size="small"
        dataSource={cashlog?.Data.map((item: WalletRecordTYpe) => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'UpdateDate',
            title: i18n.t('transactionTime'),
            width: 200,
            render: (val) => common.timeS2L(val)
          },
          {
            dataIndex: 'KeyId',
            title: i18n.t('type'),
            width: 150,
            render: (val) => AGENT_LOG[val] ? i18n.t(AGENT_LOG[val]) : val
          },
          {
            dataIndex: 'TargetAccount',
            title: i18n.t('agentMemberAccount'),
            width: 150,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            width: 200,
            render: (val) => $f(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('transactionAmount'),
            align: 'right',
            width: 200,
            render: (val) => <div className={$c(val)}>{$f(val)}</div>
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            width: 200,
            render: (val) => $f(val)
          },
        ]}
        pagination={false}
      />
      <LayoutPagination total={cashlog ? cashlog.TotalRecord : 0} setPage={setPage} page={page} />
    </Modal>
  )
}

// 換線
export const PopupExchange = ({ isOpen, close, AgentId, Account, Level, refresh }: any) => {
  const [form] = useForm();
  const { data: Options, isValidating } = $get({
    url: 'api/agent/settlecycle/list',
    params: { AgentId },
    allow: !!AgentId
  })

  const onFinish = (formData: any) => {
    $post({
      url: 'api/agent/changeagent',
      send: {
        AgentId,
        TargetAgentAccount: formData.changeAgentAccount
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        refresh();
        onClose();
      },
      resCode: RESPONSE_CODE_CHANGELINE
    })
  };

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={`${Level}${i18n.t('agents')}／${Account}／${i18n.t('currencyExchange')}`}
      open={isOpen}
      centered
      onCancel={onClose}
      footer={false}
      width={400}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Row align="middle">
              <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
              <span style={{ marginLeft: 5 }}>{i18n.t('considerationsForCurrencyExchange')}：</span>
            </Row>
            <div className="pl-2">1.{i18n.t('beforeProceedingWithCurrencyExchangeTheSettlementPeriodForAgentsMustBeConsistentBeforeAndAfterTheExchange')}</div>
            <div className="pl-2">2.{i18n.t('accountSearchWillOnlyListAgentsWithTheSameSettlementPeriod')}</div>
          </Col>
          <Col span={24}>
            <Form.Item name="changeAgentAccount" label={i18n.t('account')} required
              rules={[{ required: true }]}>
              <Select
                loading={isValidating}
                showSearch
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={
                  Options ?
                    Options?.Data.map((item: AgentSettleCycle) => (
                      { value: item.Account, label: item.Account }
                    )) : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 詳情銀行卡
export const PopupAgentBankDetail = ({ isOpen, close, currentItem, mutate }: any) => {
  const { data: $s } = useSite();
  const [form] = useForm();

  const { data: bank, isValidating } = $get({
    url: 'api/withdrawmethod/one',
    params: { Id: currentItem.Id },
    allow: !!currentItem.Id
  });

  useEffect(() => {
    if (bank && isOpen) {
      form.setFieldsValue({
        bankName: bank.Data.BankName,
        openingBranch: bank.Data.Branch,
        accountNumber: bank.Data.Account,
        accountName: bank.Data.Name,
        bankProvince: bank.Data.State,
        city: bank.Data.City,
        PhonePrefix: bank.Data.PhonePrefix.replace('+', ''),
        PhoneNumber: bank.Data.PhoneNumber,
        currencyType: bank.Data.Crypto,
        networkType: bank.Data.CryptoType,
        address: bank.Data.CryptoAddress,
        qrcode: JSON.parse(bank.Data.Photo)[0]
      })
    }
  }, [bank, isOpen])

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      forceRender
      footer={
        <Row justify="center">
          <Button key="cancel" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Form layout="vertical" form={form}>
        <Spin spinning={isValidating}>
          <Row gutter={[12, 12]}>
            {
              bank?.Data.BankName &&
              <>
                <Col span={24}>
                  <Form.Item name="bankName" label={i18n.t('bankName')} required>
                    <Input disabled />
                  </Form.Item>
                </Col>

                {$s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? '' :
                  <Col span={24}>
                    <Form.Item name="openingBranch" label={i18n.t('openingBranch')} required>
                      <Input disabled />
                    </Form.Item>
                  </Col>}

                <Col span={24}>
                  <Form.Item name="accountNumber" label={i18n.t('accountNumber')} required>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </>
            }
            {
              bank?.Data.BankName ? <>
                <Col span={24}>
                  <Form.Item name="accountName" label={i18n.t('accountName')} required>
                    <Input disabled />
                  </Form.Item>
                </Col>
                {$s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? '' :
                  <>
                    <Col span={24}>
                      <Form.Item name="bankProvince" label={i18n.t('bankCounty')}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="city" label={i18n.t('city')}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="PhonePrefix" label={i18n.t('countryCode')}>
                        <Input disabled addonBefore={'+'} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item name="PhoneNumber" label={i18n.t('phoneNumber')}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </>
                }
              </> : <>
                <Col span={24}>
                  <Form.Item name="currencyType" label={i18n.t('currency')} required>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="networkType" label={i18n.t('networkType')} required>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="address" label={i18n.t('userAddress')} required>
                    <Input disabled />
                  </Form.Item>
                </Col>
                {form.getFieldValue('qrcode') &&
                  <Col span={24}><Form.Item name="qrcode" label={i18n.t('QR Code')}>
                    <Image height={100} src={form.getFieldValue('qrcode')} />
                  </Form.Item>
                  </Col>}
              </>
            }
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}

// 棄用
export const PopupDeleteAgentBank = ({ isOpen, close, currentItem, mutate }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/withdrawmethod/agent/delete',
      send: { Id: currentItem.Id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        close();
        mutate();
      }
    })
  };

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" key="confirm" type="primary" loading={isLoading} onClick={onFinish}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}

// 返水/占成審核
export const PopupPaddingReview = ({ isOpen, close, currentBill, mode, mutate }: any) => {
  const icon = useIcon();
  const { permissions: $p } = useAccount();
  const [reviewList, setReviewList] = useState([]);
  const { data: _commissionticketList, isValidating: commissionticketValidating } = $get({
    url: 'api/commissionticket/sa/list',
    params: {
      SAId: currentBill.SAId,
      Period: currentBill.Period
    },
    allow: (!!currentBill.SAId && mode === '1')
  })
  const { data: _revenueticket, isValidating: revenueticketValidating } = $get({
    url: 'api/revenueticket/sa/list',
    params: {
      SAId: currentBill.SAId,
      Period: currentBill.Period
    },
    allow: (!!currentBill.SAId && mode === '2')
  })
  useEffect(() => {
    if ((_commissionticketList || _revenueticket) && isOpen) {
      const _list = mode === '1' ? _commissionticketList : _revenueticket
      const list = _list?.Data.map((item: any) => ({
        ...item,
        key: item.AgentId,
        RealAmount: item.Amount,
        Remark: ''
      }))
      setReviewList(list)

      list.forEach((item: any) => {
        form.setFieldValue(`RealAmount-${item.AgentId}`, item.Amount);
      });
    }
  }, [_commissionticketList, _revenueticket, isOpen]);

  const onFinish = (formData: any) => {
    const send = {
      SAId: currentBill.SAId,
      Period: currentBill.Period,
    }
    const resultData = reviewList.map((item: any) => ({
      Id: item[mode === '1' ? 'CommissionTicketId' : 'RevenueTicketId'],
      Code: item[mode === '1' ? 'CommissionTicketCode' : 'RevenueTicketCode'],
      Amount: item.Amount,
      RealAmount: formData[`RealAmount-${item.AgentId}`],
      Remark: formData[`Remark-${item.AgentId}`],
    }))
    if (mode === '1') {
      $post({
        url: 'api/commissionticket/sa/receive',
        send: { ...send, CommissionTicketResultData: resultData },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          onClose();
          mutate();
        },
        resCode: RESPONSE_CODE
      })
    } else {
      $post({
        url: 'api/revenueticket/sa/receive',
        send: { ...send, RevenueTicketResultData: resultData },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          onClose();
          mutate();
        },
        resCode: RESPONSE_CODE
      })
    }
  };

  const [form] = useForm();

  const onClose = () => {
    form.resetFields();
    close();
  }

  const $editPermissions = $p('40906');

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      width={1200}
      centered
      forceRender
      title={
        <Row align="middle" gutter={10}>
          <Col>{currentBill.SAAccount}</Col>
          <Col>{icon.WithdrawStateTag(0, currentBill.Period)}</Col>
        </Row>
      }
      footer={
        <Row justify="center">
          {$editPermissions
            ? <Button type="primary" onClick={form.submit}>{i18n.t('approvaled')}</Button>
            : <Button onClick={onClose}>{i18n.t('close')}</Button>
          }
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Table
          size="middle"
          loading={commissionticketValidating || revenueticketValidating}
          dataSource={reviewList}
          columns={[
            {
              dataIndex: 'AgentAccount',
              title: `${i18n.t('account')}／${i18n.t('agentTag')}`,
              width: '50%',
              render: (val, { Level, TagsId }) => {
                const word = common.agentLevelWord(Level);
                return `${word}／${val}`
              },
            },
            {
              dataIndex: 'Amount',
              title: i18n.t('originalAmount'),
              render: (val) => $f(val)
            },
            {
              dataIndex: 'RealAmount',
              title: <><span className="require">*</span><span>{i18n.t('distributionAmount')}</span></>,
              width: 200,
              render: (val, { AgentId }) => (
                <Form.Item name={`RealAmount-${AgentId}`} rules={verify({ point: 4, negative: true })}>
                  <Input defaultValue={val} placeholder={`${i18n.t('inputData')}`} disabled={!$editPermissions} />
                </Form.Item>
              ),
            },
            {
              dataIndex: 'Remark',
              title: i18n.t('comment'),
              width: 200,
              render: (val, { AgentId }) => (
                <Form.Item name={`Remark-${AgentId}`}>
                  <Input defaultValue={val} placeholder={`${i18n.t('inputData')}`} disabled={!$editPermissions} maxLength={100} showCount />
                </Form.Item>
              ),
            },
          ]}
          pagination={false}
        />
      </Form>
    </Modal>
  )
}

// 代理標籤: 新增
export const PopupAddAgentTag = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const [showTextColor, setShowTextColor] = useState('#FFFFFF');
  const [showColor, setShowColor] = useState('#000000');
  const [title, setTitle] = useState<string>('');
  const [showTitle, setShowTitle] = useState(true);

  const onTextColorChange = (hex: string) => {
    setShowTextColor(hex);
    form.setFieldValue('textColor', hex);
  }

  const onColorChange = (hex: string) => {
    setShowColor(hex);
    form.setFieldValue('color', hex);
  }

  const onFinish = (formData: any) => {
    $post({
      url: 'api/tag/create',
      send: {
        Name: formData.tagName,
        IsShowName: formData.isShowTagName ? 1 : 0,
        TextColor: formData.textColor,
        Color: formData.color,
        Description: formData.description,
        Position: formData.sort,
        Status: formData.status
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_TAG
    })
  }

  const onClose = () => {
    close();
    form.resetFields();
    setShowTextColor('#FFFFFF');
    setShowColor('#000000');
    setShowTitle(true);
    setTitle('');
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
        tagName: null,
        isShowTagName: true,
        textColor: '#FFFFFF',
        color: '#000000',
        sort: null,
        description: null,
        status: 1
      }}>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Form.Item name="tagName" label={
              <Row gutter={12}>
                <Col>{i18n.t('tagName')}</Col>
                <Col>
                  <Tag className="center"
                    style={
                      showTextColor || showColor
                        ? {
                          color: showTextColor,
                          background: showColor,
                          minWidth: 80,
                          minHeight: 25
                        } : {}
                    }>
                    {showTitle && title ? title : (showTitle) ? i18n.t('tagName') : ''}
                  </Tag>
                </Col>
              </Row>
            } rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setTitle(e.target.value)}
                maxLength={20} showCount />
            </Form.Item>
            <Form.Item name="isShowTagName" valuePropName="checked"
              rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <Checkbox onClick={() => setShowTitle(!showTitle)}>
                {i18n.t('showTagName')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="textColor" label={i18n.t('tagFontColor')}
              rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <ColorPicker className="w-full" onChange={e => onTextColorChange(e.toHexString())} showText />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="color" label={i18n.t('tagBackgroundColor')}
              rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <ColorPicker className="w-full" onChange={e => onColorChange(e.toHexString())} showText />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')}
              rules={[...verify({ point: 0 }), { max: 99, type: 'number' }]}>
              <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <LayoutTextArea formName='description' formLabel={i18n.t('description')}
              formRules={[{ type: 'string', max: 200 }]} maxLength={200} />
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={i18n.t('status')} required
              rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <Select options={common.enumToOptions(ENABLE)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}
// 代理標籤: 編輯
export const PopupEditAgentTag = ({ isOpen, close, id, mutate, refresh, setRefresh }: any) => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [showTextColor, setShowTextColor] = useState('#FFFFFF');
  const [showColor, setShowColor] = useState('#000000');
  const [title, setTitle] = useState<string>('');
  const [showTitle, setShowTitle] = useState(true);

  const { data: TagInfo, mutate: mutateOne, isValidating } = $get({
    url: 'api/tag/one',
    params: { Id: id },
    allow: !!id
  })
  useEffect(() => {
    if (isOpen) mutateOne()
  }, [isOpen]);
  useEffect(() => {
    if (TagInfo && isOpen) {
      form.setFieldsValue({
        tagName: TagInfo?.Data.Name,
        isShowTagName: TagInfo?.Data.IsShowName,
        textColor: TagInfo?.Data.TextColor,
        color: TagInfo?.Data.Color,
        sort: TagInfo?.Data.Position,
        description: TagInfo?.Data.Description,
        status: TagInfo?.Data.Status
      })
      setShowTextColor(TagInfo?.Data.TextColor);
      setShowColor(TagInfo?.Data.Color);
      setTitle(TagInfo?.Data.Name);
      setShowTitle(TagInfo?.Data.IsShowName);
    }
  }, [TagInfo, isOpen]);

  const onTextColorChange = (hex: string) => {
    setShowTextColor(hex);
    form.setFieldValue('textColor', hex);
  }

  const onColorChange = (hex: string) => {
    setShowColor(hex);
    form.setFieldValue('color', hex);
  }

  const onFinish = (formData: any) => {
    $post({
      url: 'api/tag/update',
      send: {
        Id: id,
        Name: formData.tagName,
        IsShowName: formData.isShowTagName ? 1 : 0,
        TextColor: formData.textColor,
        Color: formData.color,
        Description: formData.description,
        Position: formData.sort,
        Status: formData.status
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
        mutate();
        setRefresh(!refresh);
      },
      resCode: RESPONSE_CODE_TAG
    })
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  const $editPermissions = $p('41103');

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Spin spinning={isValidating}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Form.Item name="tagName" label={
                <Row gutter={12}>
                  <Col>{i18n.t('tagName')}</Col>
                  <Col>
                    <Tag className="center"
                      style={
                        showTextColor || showColor
                          ? {
                            color: showTextColor,
                            background: showColor,
                            minWidth: 80,
                            minHeight: 25
                          } : {}
                      }>
                      {showTitle && title ? title : (showTitle) ? i18n.t('tagName') : ''}
                    </Tag>
                  </Col>
                </Row>
              } rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <Input
                  placeholder={`${i18n.t('tagName')}`}
                  maxLength={20}
                  showCount
                  onChange={e => setTitle(e.target.value)}
                  disabled={!$editPermissions}
                />
              </Form.Item>
              <Form.Item name="isShowTagName" valuePropName="checked" rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <Checkbox onClick={() => setShowTitle(!showTitle)} disabled={!$editPermissions}>
                  {i18n.t('showTagName')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="textColor" label={i18n.t('tagFontColor')} rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <ColorPicker className="w-full" onChange={e => onTextColorChange(e.toHexString())} showText
                  disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="color" label={i18n.t('tagBackgroundColor')} rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <ColorPicker className="w-full" onChange={e => onColorChange(e.toHexString())} showText
                  disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="sort" label={i18n.t('sort')}
                rules={[...verify({ point: 0 }), { max: 99, type: 'number' }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="description" label={i18n.t('description')}
                rules={[{ type: 'string', max: 200 }]}>
                <Input.TextArea
                  size="middle"
                  placeholder={`${i18n.t('inputData')}${i18n.t('description')}`}
                  maxLength={200}
                  autoSize={{ minRows: 2, maxRows: 1 }}
                  showCount
                  disabled={!$editPermissions}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <Select options={common.enumToOptions(ENABLE)} disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="center" gutter={[12, 12]}>
                <Col><Button onClick={onClose}>{i18n.t('close')}</Button></Col>
                {
                  $p('41103') &&
                  <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
                }
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal >
  )
}
// 代理標籤: 代理帳號列表
export const PopupAgentTagList = ({ isOpen, close, id, mutate, refresh }: any) => {
  const { permissions: $p } = useAccount();
  const [input, setInput] = useState('');
  const [isOpenDismissModal, setIsOpenDismissModal] = useState(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [agentId, setAgentId] = useState<number | null>(null);
  const [page, setPage] = useState<number[]>([1, 10]);

  const { data: AgentList, isValidating, mutate: mutateAg } = $get({
    url: 'api/agent/tags/list',
    params: {
      TagsId: id,
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: !!id
  });

  useEffect(() => {
    mutateAg();
  }, [refresh]);

  const onSelectChange = (selectedKey: React.Key[], selectedRecord: any) => {
    setSelected(selectedRecord);
  };

  const onSetAgentTag = () => {
    if (input.includes(',')) return message.error(i18n.t('formatIncorrect'))
    $post({
      url: 'api/agent/add/tag',
      send: {
        Account: input,
        Tag: id,
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        mutate();
        mutateAg();
        setInput('');
      },
      resCode: RESPONSE_CODE_TAG
    })
  }

  const onDelete = async (id: number) => {
    setIsOpenDismissModal(true);
    setAgentId(id);
  }

  const onClose = () => {
    close();
    setInput('');
    setSelected([]);
  }

  const onDeleteRefresh = () => {
    setSelected([]);
    mutate();
    mutateAg();
  }

  const $editPermissions = $p('41105');

  return (
    <Modal
      title={
        <Row align="middle" gutter={[12, 12]}>
          <Col style={{ fontSize: 16 }}>{i18n.t('agentAccount')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      width={500}
      centered
      footer={
        <Row justify="center">
          <Button onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Row gutter={[12, 12]}>
          {/* 總計 */}
          <Col span={24}>
            <Row align="middle">
              <Col span={12}>
                {i18n.t('tagAgent')} : {AgentList?.TotalRecord || 0}
              </Col>
              <Col span={12}>
                {i18n.t('agentTotal')} : {AgentList?.AgentTotal || 0}
              </Col>
            </Row>
          </Col>
          {/* 設定標籤 */}
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col span={18}>
                <Input
                  placeholder={`${i18n.t('agentAccount')}`}
                  value={input}
                  onChange={e => setInput(e.target.value)}
                  disabled={!$editPermissions}
                />
              </Col>
              <Col span={5}>
                <Button disabled={!input || !$editPermissions} onClick={onSetAgentTag} >
                  {i18n.t('setTag')}
                </Button>
              </Col>
            </Row>
          </Col>
          {/* 批次解除 */}
          <Col span={24}>
            <Button
              type="primary" danger
              disabled={selected.length === 0 || !$editPermissions}
              onClick={() => setIsOpenDismissModal(true)}
            >
              {i18n.t('batchRelease')}
            </Button>
          </Col>
          {/* 代理列表 */}
          <Col span={24}>
            <Table
              size="small"
              rowSelection={{
                selectedRowKeys: selected.map(item => item.key),
                onChange: onSelectChange,
              }}
              dataSource={
                AgentList?.Data.map((item: AgentTagInfo) => ({ key: item.Id, ...item })) || []
              }
              columns={[
                {
                  title: i18n.t('agentAccount'),
                  dataIndex: 'Account',
                },
                {
                  title: i18n.t('operation'),
                  width: 100,
                  render: (_, record) =>
                    <Button
                      className="size-12"
                      type="link"
                      onClick={() => onDelete(record.Id)}
                      disabled={selected.length !== 0 || !$editPermissions}
                    >
                      {i18n.t('removeTag')}
                    </Button>
                },
              ]}
              pagination={false}
            />
            <LayoutPagination total={AgentList?.TotalRecord || 0} setPage={setPage} page={page} defaultPageSize={5} />
          </Col>
        </Row>
      </Spin>
      <PopupDismissTag isOpen={isOpenDismissModal} close={() => setIsOpenDismissModal(false)}
        selected={selected} agentId={agentId} tagId={id} mutate={onDeleteRefresh} />
    </Modal >
  )
}
// 代理標籤: 批次解除詢問
export const PopupDismissTag = ({ isOpen, close, selected, agentId, tagId, mutate }: any) => {
  const [loading, setLoading] = useState(false);

  const onDelete = () => {
    if (loading) return;

    if (selected?.length !== 0) {
      setLoading(true);
      // 批次解除
      selected?.map((record: any) => (
        $post({
          url: 'api/agent/delete/batch/tag',
          send: {
            Id: record.Id,
            Tag: tagId,
          },
          resCode: RESPONSE_CODE_TAG
        })
      ))
      setTimeout(() => {
        setLoading(false);
        message.success(i18n.t('operationSuccess'));
        close();
        mutate();
      }, 1000);
    } else {
      // 單一解除
      $post({
        url: 'api/agent/delete/one/tag',
        send: {
          Id: agentId,
          Tag: tagId,
        },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          close();
          mutate();
        },
        resCode: RESPONSE_CODE_TAG
      })
    }
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close} disabled={loading}>{i18n.t('cancel')}</Button>
          <Button className="mt-1 ml-1" type="primary" onClick={onDelete} disabled={loading}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Space className="mt-1" direction="vertical">
          <div>{selected?.length !== 0 ? `${i18n.t('confirmBatchRelease')} ${selected?.length} ${i18n.t('agentTagInBatch')}?` : `${i18n.t('confirmReleaseAgentTag')} ?`}</div>
        </Space>
      </Spin>
    </Modal>
  )
}
