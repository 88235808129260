import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupEditChatAccount } from 'components/chat.component';
import { LayoutNav, LayoutPagination, LayoutTabChatSetting } from 'components/layout.component';
import useAccount from 'hooks/account.hook';
import useIcon from 'hooks/icon.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { selectFuzzyFilter, timeS2L } from 'utils/common';

// 聊天室設置

interface SearchParams {
  PermissionId?: number;
  Account?: string;
  IsOnline?: number;
}

const PageMain: React.FC = () => {
  const icon = useIcon();

  const { permissions: $p } = useAccount();
  
  const [form] = useForm();
  const [data, setData] = useState();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState<SearchParams>({});
  const {
    data: PermissionOptions,
    isValidating: PermissionLoading,
    mutate: refreshPermission
  } = $get({ url: 'api/chatsetting/permission/list' });
  const { data: AccountList, isValidating, mutate } = $get({
    url: 'api/chatsetting/account/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const onEdit = (record: any) => {
    setData(record);
    setIsOpenEdit(true);
  }

  const onFinish = (value: {
    permission: number;
    account: string;
    status: number;
  }) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      PermissionId: value.permission,
      Account: value.account,
      IsOnline: value.status
    })
    refresh();
  }

  const refresh = () => {
    refreshPermission();
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    refresh();
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <LayoutTabChatSetting activeKey='1' />
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="permission" className="w-12">
                    <Select
                      placeholder={`${i18n.t('allPermission')}`}
                      loading={PermissionLoading}
                      className="w-full"
                      optionFilterProp="children"
                      showSearch
                      filterOption={selectFuzzyFilter}
                      options={
                        PermissionOptions ?
                          [{ value: '', label: i18n.t('allPermission') },
                          ...PermissionOptions?.Data.map((item: { Id: number, Name: string }) => (
                            { value: item.Id, label: item.Name }
                          ))] : []
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="account">
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}${i18n.t('account')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="status" className="w-12">
                    <Select
                      placeholder={`${i18n.t('allStatus')}`}
                      className="w-full"
                      options={[
                        { value: '', label: i18n.t('allStatus') },
                        { value: 1, label: i18n.t('online') },
                        { value: 0, label: i18n.t('offline') },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Row gutter={[12, 12]}>
                    <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                    <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                scroll={{ x: 1500 }}
                dataSource={
                  AccountList ?
                    AccountList.Data.map((item: ChatAccountList, i: number) => (
                      {
                        key: i,
                        ChatRoomAccountId: item.ChatRoomAccountId,
                        permissionName: item.PermissionName,
                        backOfficeAccount: item.Account,
                        AccountId: item.AccountId,
                        chatNickname: item.NickName,
                        status: item.IsOnline,
                        updater: item.LastModifyAccount,
                        OperatorId: item.OperatorId,
                        updateTime: item.UpdateDate,
                        Avatar: item.Avatar
                      }
                    )) : []
                }
                columns={[
                  {
                    title: i18n.t('permissionName'),
                    dataIndex: 'permissionName',
                    width: 250
                  },
                  {
                    title: i18n.t('backOfficeAccount'),
                    dataIndex: 'backOfficeAccount',
                    width: 250
                  },
                  {
                    title: i18n.t('chatNickname'),
                    dataIndex: 'chatNickname',
                    width: 250,
                    render: (_, { chatNickname }) => chatNickname ? chatNickname : '-'
                  },
                  {
                    title: `${i18n.t('online')}／${i18n.t('status')}`,
                    dataIndex: 'status',
                    align: 'center',
                    width: 100,
                    render: (_, { status }) => icon.StatusTag(status)
                  },
                  {
                    title: i18n.t('updater'),
                    dataIndex: 'updater',
                    width: 200,
                    render: (_, { updater, OperatorId }) => (
                      updater ? updater : (OperatorId === 0 ? 'System' : '-')
                    )
                  },
                  {
                    title: i18n.t('updateTime'),
                    dataIndex: 'updateTime',
                    width: 200,
                    render: (val) => val ? timeS2L(val) : '-'
                  },
                  {
                    title: i18n.t('operation'),
                    width: 100,
                    render: (_, record) => (
                      <>
                        {$p('70202') && <Button type="link" onClick={() => onEdit(record)}>{i18n.t('edit')}</Button>}
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={AccountList ? AccountList.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>

      <PopupEditChatAccount isOpen={isOpenEdit} close={() => setIsOpenEdit(false)} data={data} refresh={refresh} />
    </div>
  );
};

export default PageMain;