import { Button, Col, Form, Input, Modal, Row, Select, Spin, Table, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RESPONSE_CODE, RESPONSE_CODE_ADJUST } from "constants/response";
import { ADJUST_TYPE, AGENT_ADJUST_STATE } from "enum/state";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { $get, $post } from "services";
import * as common from "utils/common";
import { toFormatNumber as $f, timeS2L, verify } from "utils/common";
import { LayoutTextArea } from "./layout.component";
import useAccount from "hooks/account.hook";

// 總計
export const ViewAdjustOverview = ({ data }: {
  data: {
    TotalAdjustMinusAmount: number;
    TotalAdjustPlusAmount: number;
    TotalRecord: number;
  };
}) => {

  return (
    <Table
      className="w-full"
      size="middle"
      dataSource={
        data &&
        [{
          key: 1,
          count: data.TotalRecord,
          addBalanceTotal: data.TotalAdjustPlusAmount,
          deductBalanceTotal: data.TotalAdjustMinusAmount
        }]
      }
      columns={[
        {
          title: i18n.t('count'),
          align: 'right',
          dataIndex: 'count'
        },
        {
          title: i18n.t('addBalanceTotal'),
          align: 'right',
          dataIndex: 'addBalanceTotal',
          render: (_, { addBalanceTotal }) => common.toFormatNumber(addBalanceTotal)
        },
        {
          title: i18n.t('deductBalanceTotal'),
          align: 'right',
          dataIndex: 'deductBalanceTotal',
          render: (_, { deductBalanceTotal }) => common.toFormatNumber(deductBalanceTotal)
        }
      ]}
      pagination={false}
    />
  )
}

// 財務調帳列表
export const ViewAdjustList = ({ data, mutate }: {
  data: {
    State: string;
    Data: AdjustMemberList[];
    TotalRecord: number;
  }
  mutate: () => void;
}) => {
  const { permissions: $p } = useAccount();
  const [orderId, setOrderId] = useState<number>();
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case AGENT_ADJUST_STATE.approvaled:
        return 'color-pass'
      case AGENT_ADJUST_STATE.reject:
      case AGENT_ADJUST_STATE.fail:
        return 'color-reject'
      case AGENT_ADJUST_STATE.waitApproval:
        return 'color-padding'
      default: return ''
    };
  };

  const handleOpenDetail = (id: number) => {
    setIsModalOpenDetail(true);
    setOrderId(id);
  };

  const handleOpenConfirm = (id: number) => {
    setIsModalOpenConfirm(true);
    setOrderId(id);
  };

  return (
    <>
      <Table
        className="mt-1"
        size="small"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map(item => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Code',
            title: i18n.t('order'),
            width: 250,
            fixed: 'left'
          },
          {
            dataIndex: 'Account',
            title: i18n.t('memberAccount'),
            width: 200,
            fixed: 'left'
          },
          {
            dataIndex: 'MemberLevelName',
            title: i18n.t('memberLevel'),
            width: 150,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'Type',
            title: i18n.t('transactionType'),
            width: 150,
            render: (val) => i18n.t(ADJUST_TYPE[val])
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            width: 200,
            render: (val) => val === null ? '-' : common.toFormatNumber(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('amount'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'ValidBetMultiple',
            title: i18n.t('rolloverRate'),
            align: 'right',
            width: 200,
          },
          {
            dataIndex: 'expectedValidBet',
            title: i18n.t('expectedValidBet'),
            align: 'right',
            width: 200,
            render: (_, record) => $f(parseFloat(record.Amount as any) * parseFloat(record.ValidBetMultiple as any))
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            width: 200,
            render: (val) => val ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'AuditedDate',
            title: i18n.t('auditTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'Status',
            width: 150,
            title: i18n.t('status'),
            fixed: 'right',
            render: (val, record) => (
              <>
                <div className={stateColor(val)}>{i18n.t(AGENT_ADJUST_STATE[val])}</div>
                <div>
                  {
                    val === AGENT_ADJUST_STATE.waitApproval &&
                    <>{record.CreatorAccount || 'System'}</>
                  }
                  {
                    val === AGENT_ADJUST_STATE.approvaled &&
                    <>{record.AuditorAccount || 'System'}</>
                  }
                  {
                    val === AGENT_ADJUST_STATE.reject &&
                    <>{record.AuditorAccount || 'System'}</>
                  }
                  {
                    record.Status === AGENT_ADJUST_STATE.waitApproval
                      ? i18n.t('add')
                      : i18n.t(AGENT_ADJUST_STATE[record.Status])
                  }
                </div>
              </>
            )
          },
          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (_, record) => (
              <>
                {
                 (record.Status === AGENT_ADJUST_STATE.approvaled || record.Status === AGENT_ADJUST_STATE.reject) &&
                  <Button className="size-12" type="link" onClick={() => handleOpenDetail(record.key)}>{i18n.t('detail')}</Button>
                }
                {
                  record.Status === AGENT_ADJUST_STATE.waitApproval && $p('20304') &&
                  <Button className="size-12" type="link" onClick={() => handleOpenConfirm(record.key)}>{i18n.t('audit')}</Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />

      <PopupAdjust isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} order={orderId} mutate={mutate} />
      <PopupAdjustDetail isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} order={orderId} />
    </>
  )
}

// 財務新增調帳
export const PopupAddAdjust = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const { Account: Auditor } = useAccount().info;
  const [memberAccount, setMemberAccount] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [, cancel] = useDebounce(() => {
    setMemberAccount(debouncedValue);
  }, 1000, [debouncedValue])

  const onFinish = (formData: any) => {
    $post({
      url: 'api/adjustticket/member/create',
      send: {
        Account: formData.memberAccount,
        Type: formData.transactionType,
        Amount: formData.amount,
        ValidBetMultiple: formData.rolloverRate,
        RemarkForAuditor: Auditor,
        Remark: formData.backOfficeRemarks
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_ADJUST
    })
  };

  const onClose = () => {
    close();
    setTimeout(() => {
      form.resetFields();
      setMemberAccount('');
    }, 300);
  }
  const { data: memberBalanceInfo, isValidating } = $get({
    url: 'api/member/balance/info', params: {
      Account: memberAccount,
      DateType: 5,
    }, allow: memberAccount !== '', showMessage: false
  })
  useEffect(() => {
    if (memberBalanceInfo && memberBalanceInfo.data && isOpen) {
      const balance = parseFloat(memberBalanceInfo.data.Balance)
      form.setFieldsValue({
        currentBalance: $f(balance)
      })
    } else {
      form.setFieldsValue({
        currentBalance: $f(0)
      })
    }
  }, [memberBalanceInfo, isOpen]);

  return (
    <Modal 
      title={i18n.t('add')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={600}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={24}>
            <Form.Item name="memberAccount" label={i18n.t('memberAccount')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setDebouncedValue(e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Spin spinning={isValidating}>
              <Form.Item name="currentBalance" label={i18n.t('currentBalance')}>
                <Input disabled />
              </Form.Item>
            </Spin>
          </Col>
          <Col span={12}>
            <Form.Item name="transactionType" label={i18n.t('transactionType')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={common.enumToOptions(ADJUST_TYPE)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="amount" label={i18n.t('amount')} rules={verify({ zero: false, point: 4, max: 999999999.9999 })}>
              <Input typeof="number" placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <LayoutTextArea formName="backOfficeRemarks" formLabel={i18n.t('backOfficeRemarks')}
              formRules={[{ required: true, type: 'string', max: 100 }]} />
          </Col>
        </Row>
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
          <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
        </Row>
      </Form>
    </Modal>
  )
}
// 調帳訂單 詳情
export const PopupAdjustDetail = ({ isOpen, close, order }: any) => {
  const [form] = useForm();
  const { data: adjustInfo, mutate, isValidating } = $get({
    url: 'api/adjustticket/one',
    params: { Id: order },
    allow: !!order
  })
  useEffect(() => {
    if (isOpen) {
      mutate()
    }
  }, [isOpen]);

  useEffect(() => {
    if (adjustInfo && isOpen) {
      // setMemberAccount(adjustInfo.OwnerAccount);
      const balance = parseFloat(adjustInfo.Data.BeforeAmount)
      form.setFieldsValue({
        memberAccount: adjustInfo.Data.OwnerAccount,
        currentBalance: $f(balance),
        transactionType: adjustInfo.Data.Type,
        amount: common.toFormatNumber(adjustInfo.Data.Amount),
        rolloverRate: adjustInfo.Data.ValidBetMultiple,
        backOfficeRemarks: adjustInfo.Data.Remark,
      })
    }
  }, [adjustInfo, isOpen]);

  const onClose = () => {
    close();
    setTimeout(form.resetFields, 100);
  }

  return (
    <Modal 
      title={i18n.t('detail')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={600}
      forceRender
    >
      <Spin spinning={isValidating}>
        <Form layout="vertical" form={form}>
          <Row gutter={[12, 12]} className="pb-1">
            <Col span={24}>
              <Form.Item name="memberAccount" label={i18n.t('memberAccount')} required>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="currentBalance" label={i18n.t('previousBalance')} required>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="transactionType" label={i18n.t('transactionType')} required>
                <Select
                  className="w-full"
                  disabled
                  placeholder={i18n.t('pleaseSelect')}
                  options={common.enumToOptions(ADJUST_TYPE)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="amount" label={i18n.t('amount')} required>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="backOfficeRemarks" label={i18n.t('backOfficeRemarks')} required>
                <Input.TextArea placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Button type="primary" onClick={onClose}>{i18n.t('close')}</Button>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}
// 財務調帳訂單 審核調帳
export const PopupAdjust = ({ isOpen, close, order, mutate }: any) => {
  const [form] = useForm();
  const { data: adjustInfo, mutate: mutateOne, isValidating } = $get({
    url: 'api/adjustticket/one',
    params: { Id: order },
    allow: !!order
  })
  const { info } = useAccount();
  useEffect(() => {
    if (isOpen) {
      mutateOne()
    }
  }, [isOpen]);
  useEffect(() => {
    if (adjustInfo && isOpen) {
      setMemberAccount(adjustInfo.Data.OwnerAccount);
      form.setFieldsValue({
        OwnerAccount: adjustInfo.Data.OwnerAccount,
        Type: adjustInfo.Data.Type,
        Amount: common.toFormatNumber(adjustInfo.Data.Amount),
        ValidBetMultiple: adjustInfo.Data.ValidBetMultiple,
        backOfficeRemarks: adjustInfo.Data.Remark,
      })
    }
  }, [adjustInfo, isOpen]);

  // 餘額
  const [memberAccount, setMemberAccount] = useState('');
  const memberBalanceInfo = $get({
    url: 'api/member/balance/info', params: {
      Account: memberAccount,
      DateType: 5,
    }, allow: memberAccount !== '', showMessage: false
  })
  useEffect(() => {
    if (memberBalanceInfo.data && memberBalanceInfo.data.data && isOpen) {
      const balance = parseFloat(memberBalanceInfo.data.data.Balance)
      form.setFieldsValue({
        currentBalance: $f(balance)
      })
    } else {
      form.setFieldsValue({
        currentBalance: $f(0)
      })
    }
  }, [memberBalanceInfo.data, isOpen]);

  const [mode, setMode] = useState<any>();
  const handleSubmit = (formData: any) => {
    const send: any = {
      Id: order,
      RemarkFromAuditor: info && info.Account,
    }
    let msg = '';
    if (mode === AGENT_ADJUST_STATE.approvaled) {
      send.Remark = formData.backOfficeRemarks
      $post({
        url: 'api/adjustticket/member/pass',
        send,
        success: () => {
          msg = i18n.t('approvaled');
          message.success(msg);
          onClose();
          mutate();
        },
        fail: (response: any) => {
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        },
        resCode: RESPONSE_CODE_ADJUST
      })
    } else {
      send.Remark = formData.backOfficeRemarks
      $post({
        url: 'api/adjustticket/member/reject',
        send,
        success: () => {
          msg = i18n.t('reject');
          message.success(msg);
          onClose();
          mutate();
        },
        fail: (response: any) => {
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        },
        resCode: RESPONSE_CODE_ADJUST
      })
    }
  }

  const onClose = () => {
    close();
    setTimeout(form.resetFields, 100);
  };

  return (
    <Modal 
      title={i18n.t('audit')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={600}
      forceRender
    >
      <Spin spinning={isValidating}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={[12, 12]} className="pb-1">
            <Col span={24}>
              <Form.Item name="OwnerAccount" label={i18n.t('memberAccount')} required>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="currentBalance" label={i18n.t('currentBalance')} required>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Type" label={i18n.t('transactionType')} required>
                <Select
                  className="w-full"
                  disabled
                  placeholder={i18n.t('pleaseSelect')}
                  options={common.enumToOptions(ADJUST_TYPE)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Amount" label={i18n.t('amount')} required>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="ValidBetMultiple" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <LayoutTextArea formName="backOfficeRemarks" formLabel={i18n.t('backOfficeRemarks')}
                formRules={[{ required: true, type: 'string', max: 100 }]} />
            </Col>
          </Row>
          <Row justify="center" gutter={[12, 12]}>
            <Col><Button type="primary" danger onClick={() => {
              setMode(AGENT_ADJUST_STATE.reject);
              form.submit();
            }}>{i18n.t('reject')}</Button></Col>
            <Col><Button type="primary" onClick={() => {
              setMode(AGENT_ADJUST_STATE.approvaled);
              form.submit();
            }}>{i18n.t('approvaled')}</Button></Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}


// 代理總計
export const ViewAdjustAgentOverview = ({ data }: {
  data: {
    TotalRecord: number;
    TotalAdjustPlusAmount: number;
    TotalAdjustMinusAmount: number;
  };
}) => {

  return (
    <Table
      size="middle"
      dataSource={
        data && [
          {
            amount: data.TotalRecord,
            addTotal: data.TotalAdjustPlusAmount,
            deductTotal: data.TotalAdjustMinusAmount
          }
        ]
      }
      columns={[
        {
          title: i18n.t('count'),
          dataIndex: 'amount',
          width: '33%',
          align: 'right'
        },
        {
          title: i18n.t('addBalanceTotal'),
          dataIndex: 'addTotal',
          width: '33%',
          align: 'right',
          render: (_, { addTotal }) => <>{common.toFormatNumber(addTotal)}</>
        },
        {
          title: i18n.t('deductBalanceTotal'),
          dataIndex: 'deductTotal',
          width: '33%',
          align: 'right',
          render: (_, { deductTotal }) => <>{common.toFormatNumber(deductTotal)}</>
        },
      ]}
      pagination={false}
    />
  )
}

// 代理調帳
export const ViewAdjustAgentList = ({ data, mutate }: {
  data: {
    State: string;
    Data: AgentListType[];
    TotalRecord: number;
  };
  mutate: () => void;
}) => {
  const { permissions: $p } = useAccount();
  const [id, setId] = useState<number>(0);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [isOpenAdjustModal, setIsOpenAdjustModal] = useState(false);

  //color
  const handleStateColor = (type: number) => {
    switch (type) {
      case AGENT_ADJUST_STATE.approvaled:
        return 'color-pass'
      case AGENT_ADJUST_STATE.reject:
        return 'color-reject'
      case AGENT_ADJUST_STATE.waitApproval:
        return 'color-reviewing'
      default: return 'color-padding'
    }
  }

  const handleAdjust = (id: number) => {
    setIsOpenAdjustModal(true);
    setId(id);
  }

  const handleAdjustDetail = (id: number) => {
    setIsOpenDetailModal(true);
    setId(id);
  }

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item) => ({ key: item.Id, ...item }))}
        columns={[
          {
            title: i18n.t('orderNumber'),
            dataIndex: 'Code',
            width: 300,
            fixed: 'left'
          },
          {
            title: i18n.t('agentAccount'),
            dataIndex: 'AgentAccount',
            width: 200,
            render: (val, { AgentLevel }) => (
              <>
                {AgentLevel === 0 ? i18n.t('generalAgent') : `${AgentLevel}${i18n.t('agents')}`}
                ／{val}
              </>
            )
          },
          {
            title: i18n.t('generalAgent'),
            dataIndex: 'SAAccount',
            width: 200
          },
          {
            dataIndex: 'Type',
            title: i18n.t('transactionType'),
            width: 100,
            render: (val) => i18n.t(ADJUST_TYPE[val])
          },
          {
            title: i18n.t('previousBalance'),
            dataIndex: 'BeforeAmount',
            align: 'right',
            width: 200,
            render: (val, { Status }) => (
              Status === AGENT_ADJUST_STATE.approvaled
                ? common.toFormatNumber(val)
                : '-'
            )
          },
          {
            title: i18n.t('amount'),
            dataIndex: 'Amount',
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            title: i18n.t('BalanceAfterModification'),
            dataIndex: 'AfterAmount',
            align: 'right',
            width: 200,
            render: (val) => val ?
              common.toFormatNumber(val) :
              '-'
          },
          {
            title: i18n.t('createTime'),
            dataIndex: 'CreateDate',
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            title: i18n.t('auditTime'),
            dataIndex: 'AuditedDate',
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            title: i18n.t('status'),
            width: 150,
            fixed: 'right',
            render: (_, record) => (
              <>
                <div className={handleStateColor(record.Status)}>{i18n.t(AGENT_ADJUST_STATE[record.Status])}</div>
                {
                  record.Status === AGENT_ADJUST_STATE.waitApproval
                    ? <div>{record.CreatorAccount || 'System'} {i18n.t('add')}</div>
                    : <div>{record.AuditorAccount || 'System'} {i18n.t(AGENT_ADJUST_STATE[record.Status])}</div>
                }
              </>
            )
          },
          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (_, record) => (
              <>
                {(
                  record.Status === AGENT_ADJUST_STATE.approvaled ||
                  record.Status === AGENT_ADJUST_STATE.fail ||
                  record.Status === AGENT_ADJUST_STATE.reject) &&
                  <Button type='link' onClick={() => handleAdjustDetail(record.Id)}>{i18n.t('detail')}</Button>
                }
                {
                  record.Status === AGENT_ADJUST_STATE.waitApproval && $p('40704') &&
                  <Button type='link' onClick={() => handleAdjust(record.Id)}>{i18n.t('audit')}</Button>
                }
              </>
            )
          },
        ]}
        pagination={false}
      />
      <PopupAgentDetailAdjust isOpen={isOpenDetailModal} close={() => setIsOpenDetailModal(false)} id={id} />
      <PopupAgentAdjustAgent isOpen={isOpenAdjustModal} close={() => setIsOpenAdjustModal(false)} id={id} mutate={mutate} />
    </>
  )
}

// 代理新增調帳
export const PopupAgentAddAdjust = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const { Account: Auditor } = useAccount().info;
  const [agentAccount, setAgentAccount] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [, cancel] = useDebounce(() => {
    setAgentAccount(debouncedValue);
  }, 1000, [debouncedValue])

  const onFinish = (formData: any) => {
    $post({
      url: 'api/adjustticket/agent/create',
      send: {
        AgentAccount: formData.agentAccount,
        Type: formData.transactionType,
        Amount: formData.amount,
        ValidBetMultiple: 0,
        RemarkForAuditor: Auditor,
        Remark: formData.backOfficeRemarks
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE
    })
  }

  const onClose = () => {
    close();
    setTimeout(() => {
      form.resetFields();
      setAgentAccount('');
      setDebouncedValue('');
    }, 300);
  }
  const { data: agentOne, isValidating } = $get({
    url: 'api/agent/onelite',
    params: { Account: agentAccount },
    allow: !!agentAccount,
    showMessage: false
  })
  useEffect(() => {
    if (agentOne?.Data && isOpen) {
      const balance = parseFloat(agentOne.Data.Balance)
      form.setFieldsValue({
        currentBalance: $f(balance)
      })
    } else {
      form.setFieldsValue({
        currentBalance: $f(0)
      })
    }
  }, [agentOne, isOpen]);

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      width={500}
      centered
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="agentAccount" label={i18n.t('agentAccount')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} onChange={(e) => setDebouncedValue(e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Spin spinning={isValidating}>
              <Form.Item name="currentBalance" label={i18n.t('currentBalance')} >
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Spin>
          </Col>
          <Col span={12}>
            <Form.Item className="w-full" name="transactionType" label={i18n.t('transactionType')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={[
                  { value: 1, label: `${i18n.t('addBalance')}` },
                  { value: -1, label: `${i18n.t('deductBalance')}` },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="amount" label={i18n.t('amount')} rules={verify({ zero: false, point: 4, max: 999999999.9999 })}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <LayoutTextArea formName="backOfficeRemarks" formLabel={i18n.t('backOfficeRemarks')}
              formRules={[{ required: true, type: 'string', max: 100 }]} />
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col><Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col><Button key="confirm" type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 代理調帳 詳情
export const PopupAgentDetailAdjust = ({ isOpen, close, id }: any) => {
  const [form] = useForm();
  const { data: adjustInfo, isValidating } = $get({
    url: 'api/adjustticket/one',
    params: { Id: id },
    allow: !!id
  })
  useEffect(() => {
    if (adjustInfo && isOpen) {
      // setAgentAccount(adjustInfo.OwnerAccount)
      const balance = parseFloat(adjustInfo.Data.BeforeAmount)
      form.setFieldsValue({
        agentAccount: adjustInfo.Data.OwnerAccount,
        currentBalance: $f(balance),
        transactionType: adjustInfo.Data.Type,
        amount: adjustInfo.Data.Amount,
        backOfficeRemarks: adjustInfo.Data.Remark
      })
    }
  }, [adjustInfo, isOpen])

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      width={500}
      centered
      forceRender
      footer={
        <Row justify="center" gutter={12}>
          <Col><Button key="cancel" onClick={close}>{i18n.t('close')}</Button></Col>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Form layout="vertical" form={form}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item name="agentAccount" label={i18n.t('agentAccount')} required>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="currentBalance" label={i18n.t('previousBalance')}>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="transactionType" label={i18n.t('transactionType')} required>
                <Select
                  disabled
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  options={[
                    { value: 1, label: `${i18n.t('addBalance')}` },
                    { value: -1, label: `${i18n.t('deductBalance')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="amount" label={i18n.t('amount')} required>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="backOfficeRemarks" label={i18n.t('backOfficeRemarks')} required>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 代理調帳 審核調帳
export const PopupAgentAdjustAgent = ({ isOpen, close, id, mutate }: any) => {
  const [form] = useForm();

  const { Account: Auditor } = useAccount().info;
  const { data: adjustInfo, mutate: mutateOne, isValidating } = $get({
    url: 'api/adjustticket/one',
    params: { Id: id },
    allow: !!id
  })
  useEffect(() => {
    if (isOpen) mutateOne()
  }, [isOpen])
  useEffect(() => {
    if (adjustInfo && isOpen) {
      setAgentAccount(adjustInfo.Data.OwnerAccount)
      form.setFieldsValue({
        agentAccount: adjustInfo.Data.OwnerAccount,
        currentBalance: '',
        transactionType: adjustInfo.Data.Type,
        amount: adjustInfo.Data.Amount,
        backOfficeRemarks: adjustInfo.Data.Remark
      })
    }
  }, [adjustInfo, isOpen])

  const [agentAccount, setAgentAccount] = useState('');
  const { data: agentOne } = $get({
    url: 'api/agent/onelite',
    params: { Account: agentAccount }
  })
  useEffect(() => {
    if (agentOne?.Data && isOpen) {
      const balance = parseFloat(agentOne.Data.Balance)
      form.setFieldsValue({
        currentBalance: $f(balance)
      })
    } else {
      form.setFieldsValue({
        currentBalance: $f(0)
      })
    }
  }, [agentOne, isOpen]);

  const handlePass = () => {
    const remarks = form.getFieldValue('backOfficeRemarks');
    if (!remarks) return;
    $post({
      url: 'api/adjustticket/agent/pass',
      send: {
        Id: id,
        RemarkFromAuditor: Auditor,
        Remark: remarks
      },
      success: () => {
        message.success(i18n.t('approvaled'));
        mutate();
        onClose();
      },
      resCode: {
        ...RESPONSE_CODE_ADJUST,
        WALLET_BALANCE_MAXIMUM_LIMIT: i18n.t('agentBalanceLimitReached')
      }
    })
  }

  const handleReject = async () => {
    const remarks = form.getFieldValue('backOfficeRemarks');
    if (!remarks) return;
    $post({
      url: 'api/adjustticket/agent/reject',
      send: {
        Id: id,
        Remark: remarks,
        RemarkFromAuditor: Auditor && Auditor,
      },
      success: () => {
        message.success(i18n.t('reject'));
        mutate();
        onClose();
      },
      resCode: RESPONSE_CODE_ADJUST
    })
  }

  const onClose = () => {
    close();
    setTimeout(() => {
      form.resetFields();
      setAgentAccount('');
    }, 300);
  }

  return (
    <Modal
      title={i18n.t('audit')}
      open={isOpen}
      onCancel={close}
      width={500}
      centered
      footer={false}
      forceRender
    >
      <Spin spinning={isValidating}>
        <Form layout="vertical" form={form}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item name="agentAccount" label={i18n.t('agentAccount')} required>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="currentBalance" label={i18n.t('currentBalance')}>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="transactionType" label={i18n.t('transactionType')} required>
                <Select
                  disabled
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  options={[
                    { value: 1, label: `${i18n.t('addBalance')}` },
                    { value: -1, label: `${i18n.t('deductBalance')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="amount" label={i18n.t('amount')} required>
                <Input placeholder={`${i18n.t('inputData')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <LayoutTextArea formName="backOfficeRemarks" formLabel={i18n.t('backOfficeRemarks')}
                formRules={[{ required: true, type: 'string', max: 100 }]} />
            </Col>
            <Col span={24}>
              <Row justify="center" gutter={12}>
                <Col>
                  <Button htmlType="submit" key="cancel" type="primary" danger onClick={handleReject}>{i18n.t('reject')}</Button>
                </Col>
                <Col>
                  <Button htmlType="submit" key="confirm" type="primary" onClick={handlePass}>{i18n.t('approvaled')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}
