import { COOKIE } from "constants/cookie";
import { SOCKET_ON } from "constants/socket";
import useSite from "hooks/site.hook";
import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
import { Socket, io } from 'socket.io-client';

export const socketContext = createContext<SocketContext>({});

export const useSocket = () => {
  return useContext(socketContext);
};

export const SocketProvider = ({ children }: {
  children?: any
}) => {
  const { data: $s }: any = useSite();
  const [socket, setSocket] = useState<Socket>();
  const [socketId, setSocketId] = useState<string | undefined>("");
  const [serviceMessages, setServiceMessages] = useState<any>({});
  const [privateMessage, setPrivateMessage] = useState<any>({});
  const [notification, setNotification] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [socketReview, setSocketReview] = useState<Socket>();
  const [socketReviewId, setSocketReviewId] = useState<string | undefined>("");
  const [reviewKyc, setReviewKyc] = useState<number>(0);
  const [reviewBankcard, setReviewBankcard] = useState<number>(0);

  const token = Cookies.get(COOKIE.TOKEN);

  useEffect(() => {
    if ($s?.Domain) {
      const socket = io(`wss://chat.${$s.Domain}`, {
        transports: ['websocket'],
        secure: true,
        auth: {
          token,
          role: 'Service'
        }
      });
      setSocket(socket);

      const socketReview = io(`wss://socket.${$s.Domain}/bo`, {
        transports: ['websocket'],
        secure: true,
        auth: {
          token
        }
      });
      setSocketReview(socketReview);
    }
  }, [$s]);

  useEffect(() => {
    if (!socket) return;
    socket.off();

    // 連線
    socket.on(SOCKET_ON.連線, () => {
      setSocketId(socket.id);
    });
    // 斷線
    socket.on(SOCKET_ON.斷線, () => {
    });
    // 未登入
    socket.on(SOCKET_ON.未登入, () => {
    });
    // 客服訊息
    socket.on(SOCKET_ON.客服訊息, (msg) => {
      const message = JSON.parse(msg);
      setServiceMessages(message);
    });
    // 私人訊息
    socket.on(SOCKET_ON.私人訊息, (msg) => {
      const message = JSON.parse(msg);
      setPrivateMessage(message);
    });
    // 客服通知
    socket.on(SOCKET_ON.客服通知, (msg) => {
      const message = JSON.parse(msg);
      setSessionId(message.SessionId);
      setNotification(`${message.Notification}-${new Date()}`);
    });
    // 連線失敗
    socket.on(SOCKET_ON.連線錯誤, (msg) => {
    });

  }, [socket, socketId]);

  useEffect(() => {
    if (!socketReview) return;
    socketReview.off();

    socketReview.on(SOCKET_ON.連線, () => {
      setSocketReviewId(socketReview.id);
    });
   
    socketReview.on('kyc', (msg) => {
      const message = JSON.parse(msg);
      switch (message.Action) {
        case 'past':
          setReviewKyc(message.Quantity);
          break;
        case 'apply':
          setReviewKyc(reviewKyc + 1);
          break;
        case 'pass':
          case 'reject':
          setReviewKyc(reviewKyc - 1);
          break;
      }
    });

    socketReview.on('withdraw-bank', (msg) => {
      const message = JSON.parse(msg);
      switch (message.Action) {
        case 'past':
          setReviewBankcard(message.Quantity);
          break;
        case 'apply':
          setReviewBankcard(reviewBankcard + 1);
          break;
        case 'pass':
          case 'reject':
          setReviewBankcard(reviewBankcard - 1);
          break;
      }
    });

  }, [socketReview, socketReviewId, reviewKyc, reviewBankcard]);
  return (
    <socketContext.Provider value={{
      socket,
      serviceMessages,
      privateMessage,
      reviewKyc,
      reviewBankcard,
      notification,
      sessionId
    }}>
      {children}
    </socketContext.Provider>
  )
}