import { Button, Col, Image, message, Row, Table } from "antd";
import { Content } from 'antd/es/layout/layout';
import { PopupFrontCarousel } from 'components/front.component';
import { InquiryWindow, LayoutNav } from "components/layout.component";
import { ENABLE, SLIDE_TYPE } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from "services";
import { timeS2L } from 'utils/common';
import { RESPONSE_CODE_FRONT } from "../../../constants/response";
import useAccount from "hooks/account.hook";

// 輪播圖

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [key, setKey] = useState<number | null>(null);
  const [data, setData] = useState<any>(null);
  const { data: CarouselList, isValidating, mutate } = $get({ url: 'api/contents/mineslide/list' });
  const [count, setCount] = useState({
    WebTotalRecord: 0,
    MobileTotalRecord: 0
  })

  useEffect(() => {
    if (CarouselList) {
      setCount({
        WebTotalRecord: CarouselList.WebTotalRecord,
        MobileTotalRecord: CarouselList.MobileTotalRecord
      })
    }
  }, [CarouselList]);

  const stateColor = (state: number) => {
    switch (state) {
      case ENABLE.enabled:
        return 'color-pass'
      case ENABLE.disabled:
        return 'color-reject'
    };
  };

  const onEdit = (record: any) => {
    setData(record);
    setIsOpenAddModal(true);
  }

  const handleDelete = () => {
    $post({
      url: 'api/contents/mainslide/delete',
      send: { Id: key },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_FRONT
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            {$p('50202') &&
            <Button type="primary" onClick={() => onEdit(null)}
              disabled={count.WebTotalRecord >= 10 && count.MobileTotalRecord >= 10}>
              {i18n.t('add')}
            </Button>}
          </Col>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={CarouselList?.Data?.map((item: FrontCarousel) => ({ key: item.Id, ...item }))}
              columns={[
                {
                  dataIndex: 'Position',
                  title: i18n.t('sort'),
                  align: 'center',
                  width: 80
                },
                {
                  dataIndex: 'Category',
                  title: i18n.t('type'),
                  width: 200,
                  render: (val) => i18n.t(SLIDE_TYPE[val])
                },
                {
                  dataIndex: 'Photo_1',
                  title: i18n.t('image'),
                  render: (val) => <Image src={val} width={70} height={65} />
                },
                {
                  dataIndex: 'LinkTarget',
                  title: i18n.t('openLinkInNewWindow'),
                  width: 100,
                  render: (val) => val ? i18n.t('yes') : i18n.t('none')
                },
                {
                  dataIndex: 'LinkURL',
                  title: i18n.t('linkURL'),
                },
                {
                  dataIndex: 'Status',
                  title: i18n.t('status'),
                  width: 100,
                  render: (val) => <div className={stateColor(val)}>{i18n.t(ENABLE[val])}</div>
                },
                {
                  dataIndex: 'CreateDate',
                  title: i18n.t('createTime'),
                  width: 160,
                  render: (val: string) => timeS2L(val)
                },
                {
                  title: i18n.t('operation'),
                  width: 160,
                  render: (_, record) => (
                    <>
                      {$p('50203') && <Button type="link" onClick={() => {
                        onEdit(record)
                      }}>
                        {i18n.t('edit')}
                      </Button>}
                      {$p('50204') && <Button type="link" onClick={() => {
                        setKey(record.key);
                        setIsOpenDeleteModal(true);
                      }}>
                        {i18n.t('delete')}
                      </Button>}
                    </>
                  )
                }
              ]}
              pagination={false}
            />
          </Col>
        </Row>
      </Content>
      <PopupFrontCarousel isOpen={isOpenAddModal} close={() => setIsOpenAddModal(false)} data={data} mutate={mutate} count={count} />
      <InquiryWindow isOpen={isOpenDeleteModal} close={() => setIsOpenDeleteModal(false)} 
        msg={i18n.t('confirmDeleteData')} action={handleDelete} />
    </div >
  );
};

export default PageMain;