import { Button, Col, message, Row, Table } from "antd";
import { Content } from 'antd/es/layout/layout';
import { PopupMaintenance } from 'components/advanced.component';
import { InquiryWindow, LayoutNav, LayoutPagination } from "components/layout.component";
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from "services";
import { specialProviderName, timeS2L } from "../../../utils/common";
import useAccount from 'hooks/account.hook';
import { RESPONSE_CODE_MAINTENANCE } from "../../../constants/response";

// 系統維護

const PageMain: React.FC = () => {
  const { data: site } = useSite();
  const { permissions: $p } = useAccount();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [data, setData] = useState<{
    key: number;
    sort: number;
    provider: string;
    date: {
      start: string;
      end: string;
    }
    GameProviderId: number;
  } | null>(null);
  const [id, setId] = useState<number | null>(null);
  const [page, setPage] = useState<number[]>([1, 10]);
  const { data: ProviderList, isValidating, mutate } = $get({
    url: 'api/providermaintenance/list',
    params: {
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onAdd = (data: any) => {
    setIsOpenAdd(true);
    setData(data);
  }

  const onDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  const handleSubmit = () => {
    $post({
      url: 'api/providermaintenance/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_MAINTENANCE
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Row gutter={[0, 12]}>
          {$p('01102') && <Col span={24}>
            <Row align="middle" gutter={10}>
              <Col>
                <Button type="primary" onClick={() => onAdd(null)}>{i18n.t('add')}</Button>
              </Col>
            </Row>
          </Col>}
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={
                ProviderList?.Data.map((item: Providermaintaintenance) => ({ key: item.Id, ...item }
                )) || []
              }
              columns={[
                {
                  title: i18n.t('sort'),
                  dataIndex: 'Position',
                  align: 'center',
                  width: 80
                },
                {
                  title: `${i18n.t('gameProvider')}／${i18n.t('mainCategory')}`,
                  dataIndex: 'ProviderName',
                  render: (val, { ProviderCode, CategoryCode }) => `${specialProviderName(val, site.SiteName, ProviderCode)}${i18n.t(CategoryCode)}`
                },
                {
                  title: `${i18n.t('startTime')} - ${i18n.t('endTime')}`,
                  defaultSortOrder: "ascend",
                  render: (_, { StartDate, EndDate }) => <>{timeS2L(StartDate)} - {timeS2L(EndDate)}</>
                },
                {
                  title: i18n.t('operation'),
                  width: 100,
                  render: (_, record) => (
                    <>
                      {$p('01103') && <Button type="link" onClick={() => onAdd(record)}>
                        {i18n.t('edit')}
                      </Button>}
                      {$p('01104') && <Button type="link" onClick={() => onDelete(record.key)}>
                        {i18n.t('delete')}
                      </Button>}
                    </>
                  )
                },
              ]}
              pagination={false}
            />
            <LayoutPagination total={ProviderList?.TotalRecord || 0} setPage={setPage} page={page} />
          </Col>
        </Row>
        <PopupMaintenance isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} data={data} mutate={mutate} />
        <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={handleSubmit} />
      </Content>
    </div>
  );
};

export default PageMain;