import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { ViewTransferBank, ViewTransferCrypto } from 'components/finance.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { GATEWAY, GATEWAY_CODE_DEPOSIT, GATEWAY_CODE_WITHDRAW } from 'constants/gateway';
import { TRANSFER_TYPE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get } from 'services';

// 轉帳出入款管理

interface Search {
  GatewayCode?: string;
  CustomName?: string;
  LevelId?: number;
}

const PageMain: React.FC = () => {
  const { init, permissions: $p } = useAccount();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [type, setType] = useState(TRANSFER_TYPE.deposit);
  const [gateway, setGateway] = useState(GATEWAY.bankCard);
  const [params, setParams] = useState<Search>({
    GatewayCode: GATEWAY_CODE_DEPOSIT.線下銀行
  })
  useEffect(() => {
    if ($p('20701')) {
      setType(TRANSFER_TYPE.deposit);
      setParams({
        GatewayCode: GATEWAY_CODE_DEPOSIT.線下銀行
      })
    } else if ($p('20401')) {
      setType(TRANSFER_TYPE.withdraw);
      setParams({
        GatewayCode: GATEWAY_CODE_WITHDRAW.線下銀行
      })
    }
  }, [init]);
  const { data: DepositList, isValidating: depositLoading, mutate: refreshDeposit } = $get({
    url: 'api/depositmerchant/offline/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1],
    },
    allow: !!$p('20701') && type === TRANSFER_TYPE.deposit,
    showMessage: false
  })
  const { data: Withdrawal, isValidating: withdrawalLoading, mutate: refreshWithdrawal } = $get({
    url: 'api/withdrawmerchant/offline/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1],
    },
    allow: !!$p('20401') && type === TRANSFER_TYPE.withdraw,
    showMessage: false
  })
  const [dataList, setDataList] = useState<any>([])
  useEffect(() => {
    if (DepositList?.Data) setDataList(DepositList.Data)
    else if (Withdrawal?.Data) setDataList(Withdrawal.Data)
  }, [DepositList, Withdrawal]);

  useEffect(() => {
    onClear();
    switch (type) {
      case TRANSFER_TYPE.deposit:
        setParams({
          GatewayCode: gateway === GATEWAY.bankCard ? GATEWAY_CODE_DEPOSIT.線下銀行 : GATEWAY_CODE_DEPOSIT.線下虛擬
        })
        break;
      case TRANSFER_TYPE.withdraw:
        setParams({
          GatewayCode: gateway === GATEWAY.bankCard ? GATEWAY_CODE_WITHDRAW.線下銀行 : GATEWAY_CODE_WITHDRAW.線下虛擬
        })
        break;
    }
  }, [type, gateway]);

  const search = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      CustomName: formData.CustomName,
      LevelId: formData.LevelId,
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
  }

  const mutate = () => {
    refreshDeposit();
    refreshWithdrawal();
  }

  const modeList: any[] = [
    $p('20401') && { value: TRANSFER_TYPE.deposit, label: i18n.t('dodeposit') },
    $p('20701') && { value: TRANSFER_TYPE.withdraw, label: i18n.t('withdrawals') }
  ].filter(item => item)
  
  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search}>
          <Row gutter={[12, 12]}>
            {/* 搜尋欄位 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  {/* 切換出入款 */}
                  {modeList.length > 0 &&
                  <Select
                    className="w-12"
                    defaultValue={modeList[0].value}
                    options={modeList}
                    onChange={setType}
                  />}
                </Col>
                <Col>
                  <Select
                    className="w-12"
                    defaultValue={GATEWAY.bankCard}
                    options={[
                      { value: GATEWAY.bankCard, label: i18n.t('bankCard') },
                      { value: GATEWAY.crypto, label: i18n.t('crypto') },
                    ]}
                    onChange={setGateway}
                  />
                </Col>
                <Col>
                  <Form.Item name="CustomName">
                    <Input placeholder={`${i18n.t('displayName')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="LevelId">
                    <MemberLevelSelect name="LevelId" form={form} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {/* 列表 */}
            <Col span={24}>
              {
                gateway === GATEWAY.bankCard &&
                <Spin spinning={depositLoading || withdrawalLoading}>
                  <ViewTransferBank data={dataList} mutate={mutate} type={type} />
                  <LayoutPagination total={dataList?.TotalRecord} setPage={setPage} page={page} />
                </Spin>
              }
              {
                gateway === GATEWAY.crypto &&
                <Spin spinning={depositLoading || withdrawalLoading}>
                  <ViewTransferCrypto data={dataList} mutate={mutate} type={type} />
                  <LayoutPagination total={dataList?.TotalRecord} setPage={setPage} page={page} />
                </Spin>
              }
            </Col>
          </Row>
        </Form>
      </Content >

    </div >
  );
};

export default PageMain;