import { Col, Row, Select, Spin, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabMember } from 'components/layout.component';
import { PopupStatusModal } from 'components/wallet.component';
import { RESPONSE_CODE_MEMBER_PROVIDER_STATUS } from 'constants/response';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { specialProviderName } from 'utils/common';

// 會員編輯 > 遊戲商資料

interface ProviderInfo {
  Id: number;
  MemberId: number;
  ProviderId: number;
  ProviderName: string;
  CategoryCode: string;
  Status: number;
}

const PageMain: React.FC = () => {
  const { data: site } = useSite();
  const { init, permissions: $p } = useAccount();
  const { id , account, agId} = useParams();
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  const [modalType, setModalType] = useState<number | null>();
  const [data, setData] = useState<ProviderInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { data: MemberProviderList, mutate, isValidating } = $get({
    url: 'api/memberprovidersetting/list',
    params: { MemberId: id }
  });


  useEffect(() => {
    if (MemberProviderList) {
      setData(MemberProviderList?.Data);
    }
  }, [MemberProviderList])

  const handleStatus = (checked: boolean, item: any) => {
    $post({
      url: 'api/memberprovidersetting/update',
      send: {
        MemberId: id,
        Id: item.Id || null,
        ProviderId: item.ProviderId,
        Status: checked ? 1 : 0
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_MEMBER_PROVIDER_STATUS
    }, setIsLoading)
  };
  const handleAllStatus = (option: number) => {
    setModalType(option);
    setIsOpenStatusModal(true);
  }

  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
    if (init) {
      setColumns([
        {
          dataIndex: 'ProviderName',
          title: i18n.t('gameProvider'),
          render: (val: any, { ProviderCode, CategoryCode }: any) => `${specialProviderName(val, site.SiteName, ProviderCode)}-${i18n.t(CategoryCode)}`
        },
        {
          dataIndex: 'GameAccount',
          title: i18n.t('gameProviderAccount'),
          render: (val: any) => val
        },
        {
          dataIndex: 'Status',
          title: i18n.t('operation'),
          width: 150,
          render: (val: any, record: any) => (
            <Row justify="start" align="middle" gutter={[12, 12]}>
              <Col>
                <Switch onChange={c => handleStatus(c, record)} checked={val} loading={isLoading}
                  disabled={!$p('10302')} />
              </Col>
            </Row>
          )
        },
      ])
    }
  }, [init]);

  return (
    <div id="container">
      <LayoutNav id={id || 0} account={account || ''} agId={agId}/>
      <Content className="pr-2 pl-2 pb-2">
        <LayoutTabMember activeKey="2" id={id || 0} account={account} agId={agId} />
        <Row>
          <Row gutter={[12, 12]}>
            {
              ($p('10303') && $p('10302')) &&
              <Col>
                <Select
                  className="w-12"
                  placeholder={i18n.t('gameStatus')}
                  onSelect={handleAllStatus}
                  options={[
                    { value: 1, label: i18n.t('enableAllGames') },
                    { value: 0, label: i18n.t('disableAllGames') }
                  ]}
                />
              </Col>
            }
          </Row>
          {/* 遊戲錢包資料 */}
          <Col span={24} className="mt-1">
            <Spin spinning={isValidating}>
              <Row>
                <Col span={8}>
                  <Table
                    size="small"
                    pagination={false}
                    dataSource={data?.filter((_, i) => i % 3 === 0).map(item => ({ ...item, key: item.ProviderId }))}
                    columns={columns}
                  />
                </Col>
                <Col span={8}>
                  <Table
                    size="small"
                    className="border-04"
                    pagination={false}
                    dataSource={data?.filter((_, i) => i % 3 === 1).map(item => ({ ...item, key: item.ProviderId }))}
                    columns={columns}
                  />
                </Col>
                <Col span={8}>
                  <Table
                    size="small"
                    pagination={false}
                    dataSource={data?.filter((_, i) => i % 3 === 2).map(item => ({ ...item, key: item.ProviderId }))}
                    columns={columns}
                  />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Content>
      <PopupStatusModal isOpen={isOpenStatusModal} close={() => setIsOpenStatusModal(false)}
        id={id || 0} type={modalType} mutate={mutate}
      />
    </div >
  );
};

export default PageMain;