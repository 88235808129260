import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { ViewWithdrawList } from 'components/finance.component';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import { MEMBER_BANK_STATE, SEARCH_DATE_TYPE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get } from 'services';
import { enumToOptions, timeL2S, verify } from "utils/common";

// 銀行卡驗證

const PageMain: React.FC = () => {
  const { status } = useParams();

  const [form] = useForm();
  const [startType, setStartType] = useState('CreateStartDate');
  const [endType, setEndType] = useState('CreateEndDate');
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);

  const [params, setParams] = useState<any>({
    CreateStartDate: timeL2S(date[0]),
    CreateEndDate: timeL2S(date[1]),
    Status: status ? Number(status) : null
  })
  const { data: verifyList, isValidating, mutate } = $get({
    url: 'api/withdrawmethod/list',
    params: {
      ...params,
      Role: 0,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const handleDateType = (type: number) => {
    switch (type) {
      case 0:
        setStartType('CreateStartDate');
        setEndType('CreateEndDate');
        break;
      case 1:
        setStartType('VerifiedStartDate');
        setEndType('VerifiedEndDate');
        break;
    }
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Account: formData.Account,
        PhoneNumber: formData.PhoneNumber,
        PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
        Status: formData.Status,
        [startType]: timeL2S(date[0]),
        [endType]: timeL2S(date[1])
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setReset(!reset);
    setStartType('CreateStartDate');
    setEndType('CreateEndDate');
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: SEARCH_DATE_TYPE.createTime,
          Status: status ? Number(status) : null
        }}>
          {/* 搜尋列 */}
          <Row align="middle" gutter={[12, 12]}>
            <Col>
              <Form.Item name="Account">
                <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="PhonePrefix" rules={verify({ point: 7, required: false })}>
                <Input className="w-12" autoComplete="off" placeholder={`${i18n.t('enterCountryCode')}`} addonBefore={'+'} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="PhoneNumber">
                <Input className="w-12" placeholder={`${i18n.t('phoneNumber')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="Status">
                <Select
                  placeholder={i18n.t('status')}
                  options={[
                    { value: "", label: `${i18n.t('all')}` },
                    ...enumToOptions(MEMBER_BANK_STATE)
                  ].filter(item => !(item.value === 9))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" align="middle" gutter={[12, 12]}>
            <Col>
              <Form.Item className="w-12" name="TimeMode">
                <Select
                  onChange={handleDateType}
                  options={enumToOptions(SEARCH_DATE_TYPE)}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={191} date={date} setDate={setDate} initDate={reset} />
            <Col>
              <Button htmlType="submit" type="primary">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
          {/* 搜尋結果 */}
          <Row className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewWithdrawList data={verifyList} mutate={mutate} />
                <LayoutPagination total={verifyList ? verifyList.TotalRecord : 0} setPage={setPage} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;