import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import { OPERATION_TYPE } from 'enum/operation';
import { PERMISSION_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { enumToOptions, selectFuzzyFilter, timeS2L } from 'utils/common';

// 操作記錄(舊)

const PageMain: React.FC = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD'),
    dayjs().endOf('day').format('YYYY-MM-DD')
  ]);
  const [reset, setReset] = useState(false);
  const [params, setParams] = useState<any>({
    StartDate: `${date[0]} 00:00:00`,
    EndDate: `${date[1]} 23:59:59`,
  });
  const { data: Operations, isValidating, mutate } = $get({
    url: 'api/accesslog/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const [formChanged, setFormChanged] = useState(false);
  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (formChanged) {
      setFormChanged(false);
      setParams({
        StartDate: `${date[0]} 00:00:00`,
        EndDate: `${date[1]} 23:59:59`,
        KeyId: formData.KeyId,
        PermissionId: formData.PermissionId,
        Account: formData.Account,
      });
    } else mutate();
  };

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
  }

  const { data: permission } = $get({
    url: 'api/permission/list',
    params: {
      PageIndex: 1,
      PageSize: 999,
      Status: PERMISSION_STATE.enabled,
    }
  });

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} onValuesChange={setFormChanged}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="KeyId" className="w-12">
                    <Select
                      showSearch
                      filterOption={selectFuzzyFilter}
                      className="w-full"
                      placeholder={`${i18n.t('pageOperationType')}`}
                      options={[
                        { value: '', label: `${i18n.t('all')}` },
                        ...enumToOptions(OPERATION_TYPE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="PermissionId" className="w-12">
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('backOfficePermissions')}`}
                      options={[
                        { value: '', label: i18n.t('all') },
                        ...((permission && permission.Data) || []).map((item: any) => ({
                          value: item.Id, label: item.Name
                        }))
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="Account">
                    <Input placeholder={`${i18n.t('backOfficeAccount')}`} />
                  </Form.Item>
                </Col>
                <DatePickerCol width={150} date={date} setDate={setDate} initDate={reset} format={'YYYY-MM-DD'}
                  setFormChanged={setFormChanged} />
                <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                dataSource={
                  Operations?.Data?.map((item: OperationInfo) => {
                    // const edited = JSON.stringify(item.Params, (key, value) => (
                    //   typeof value === 'string' ? value : {}
                    // ))
                    return {
                      ...item,
                      key: item.Id,
                      KeyId: Number(item.KeyId),
                      // Params: JSON.parse(edited),
                    }
                  })
                }
                columns={[
                  {
                    dataIndex: 'KeyId',
                    title: i18n.t('pageOperationType'),
                    width: 400,
                    render: (val) => i18n.t(`${OPERATION_TYPE[val]}`) || val
                  },
                  {
                    dataIndex: 'Params',
                    title: i18n.t('edited'),
                    render: (val, record) => {
                      let res = ''
                      if (val.includes('UPDATE')) {
                        const paramsJson = JSON.parse(val);
                        const after = paramsJson.UPDATE.AFTER;

                        if (typeof after === 'string') {
                          res = after;
                        } else if (typeof after === 'object') {
                          res = JSON.stringify(after);
                        }
                      } else {
                        res = val
                      }
                      return <div style={{ width: '800px' }}>{res}</div>
                    },
                    // render: (val) => {
                    //   let parsedData = {};
                    //   let title: string[];
                    //   try {
                    //     parsedData = JSON.parse(val);
                    //   } catch (error) {
                    //     parsedData = { item: val };
                    //   }
                    //   title = Object.keys(parsedData);
                    //   return (
                    //     <List
                    //       itemLayout="vertical"
                    //       dataSource={Object.values(parsedData)}
                    //       renderItem={(item: any, index: number) => (
                    //         <div key={index}>
                    //           <Typography.Text keyboard>
                    //             {title[0] === 'item' ? i18n.t('operation') : i18n.t(title[0])}
                    //           </Typography.Text>
                    //           <List.Item>
                    //             {
                    //               typeof item[0] === 'string'
                    //                 ? <Typography.Text code style={{ maxWidth: 400 }}>
                    //                   {item}
                    //                 </Typography.Text>
                    //                 : <pre style={{ whiteSpace: 'pre-wrap', maxWidth: 400 }}>
                    //                   {JSON.stringify(item)}
                    //                 </pre>
                    //             }
                    //           </List.Item>
                    //         </div>
                    //       )}
                    //     />
                    //   )
                    // }
                  },
                  {
                    dataIndex: 'CreateDate',
                    title: i18n.t('operationTime'),
                    width: 180,
                    render: (val: string) => timeS2L(val)
                  },
                  {
                    dataIndex: 'PermissionName',
                    title: i18n.t('backOfficePermissions'),
                    width: 180,
                  },
                  {
                    dataIndex: 'OperatorAccount',
                    title: i18n.t('backOfficeAccount'),
                    width: 180,
                  },
                  {
                    dataIndex: 'IP',
                    title: i18n.t('IP'),
                    width: 180,
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={Operations ? Operations.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;