import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, InquiryWindow, LayoutNav, LayoutPagination } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { PopupAllpassPromotionRebate } from 'components/promotion.component';
import { RESPONSE_CODE_REBATE } from 'constants/response';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { SETTLE_CYCLE } from 'enum/promotion';
import { PROMOTION_REBATE_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from 'services';
import * as common from 'utils/common';
import { timeL2S, timeS2L } from "utils/common";

// 返水優惠查詢

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [allPass, setAllPass] = useState(false);
  const [allReject, setAllReject] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [isOpenBatchRejectModal, setIsOpenBatchRejectModal] = useState(false);
  const [isModalOpenAllpass, setIsModalOpenAllpass] = useState(false);
  const [startDate, setStartDate] = useState('CreateStartDate');
  const [endDate, setEndDate] = useState('CreateEndDate');
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [params, setParams] = useState<any>({
    CreateStartDate: timeL2S(date[0]),
    CreateEndDate: timeL2S(date[1]),
  });
  const { data: rebateList, isValidating, mutate } = $get({
    url: 'api/commissionticket/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const onFinish = (formData: any) => {
    if (date) {
      setParams({
        Code: formData.order,
        Account: formData.memberAccount,
        MemberLevel: formData.LevelId,
        Status: formData.status,
        UpMemberAccount: formData.recommendedMembers,
        AGAccount: formData.agentAccount,
        SAAccount: formData.masterAgent,
        [startDate]: timeL2S(date[0]),
        [endDate]: timeL2S(date[1]),
      })
    }
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
    setStartDate('CreateStartDate');
    setEndDate('CreateEndDate');
  }

  const handleDateType = (type: number) => {
    switch (type) {
      case 0:
        setStartDate('CreateStartDate');
        setEndDate('CreateEndDate')
        break;
      case 1:
        setStartDate('AuditedStartDate');
        setEndDate('AuditedEndDate')
        break;
      case 2:
        setStartDate('ReceivedStartDate');
        setEndDate('ReceivedEndDate')
        break;
    }
  }

  const colorChange = (type: number) => {
    switch (type) {
      case PROMOTION_REBATE_STATE.received:
      case PROMOTION_REBATE_STATE.dispatched:
        return 'color-pass'
      case PROMOTION_REBATE_STATE.pendingDistribution:
        return 'color-padding'
      case PROMOTION_REBATE_STATE.reject:
        return 'color-reject'
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // 批次拒絕
  const handleAllReject = () => {
    $post({
      url: 'api/commissionticket/reject',
      send: { Id: selectedRowKeys },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        setSelectedRowKeys([]);
        mutate();
      },
      fail: (response: any) => {
        if (response.Message.includes("STATUS_FAIL")) {
          message.error(i18n.t('operationRepeatedPleaseRefreshPage'));
        } else {
          message.error(RESPONSE_CODE_REBATE[response.Message as keyof typeof RESPONSE_CODE_REBATE]);
        }
      },
      showMessage: false,
      resCode: RESPONSE_CODE_REBATE
    }, setAllReject)
  }

  // 聖經欄位顯示設定
  const [columns, setColumns] = useState<any>([
    {
      dataIndex: 'Code',
      title: i18n.t('order'),
      width: 250,
      fixed: 'left',
    },
    {
      dataIndex: 'MemberAccount',
      title: i18n.t('memberAccount'),
      width: 180,
    },
    {
      dataIndex: 'MemberLevelName',
      title: i18n.t('memberLevel'),
      width: 120,
      render: (val: any) => val || '-'
    },
    {
      dataIndex: 'UpMemberAccount',
      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
      width: 250,
      render: (_: any, record: any) => (
        <>
          <span>{record.UpMemberAccount || '-'}</span><span> / </span>
          <span>{record.AGAccount || '-'}</span><span> / </span>
          <span>{record.SAAccount || '-'}</span>
        </>
      )
    },
    {
      dataIndex: 'SettleCycle',
      title: i18n.t('rebatePeriod'),
      width: 80,
      render: (val: any) => i18n.t(SETTLE_CYCLE[val])
    },
    {
      dataIndex: 'Amount',
      title: i18n.t('amount'),
      align: 'right',
      width: 180,
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'ValidBetMultiple',
      title: i18n.t('rolloverRate'),
      align: 'right',
      width: 80,
    },
    {
      dataIndex: 'CreateDate',
      title: i18n.t('createTime'),
      width: 180,
      render: (val: any) => timeS2L(val)
    },
    {
      dataIndex: 'AuditedTime',
      title: i18n.t('distributionTime'),
      width: 180,
      render: (val: any) => timeS2L(val)
    },
    {
      dataIndex: 'ReceivedTime',
      title: i18n.t('takeTime'),
      width: 180,
      render: (val: any) => timeS2L(val)
    },
    {
      dataIndex: 'Status',
      title: i18n.t('status'),
      width: 150,
      render: (val: any, { OperatorAccount }: any) => (
        <>
          <div className={colorChange(val)}>
            {i18n.t(PROMOTION_REBATE_STATE[val])}
          </div>
          {
            val === PROMOTION_REBATE_STATE.pendingDistribution ?
              <div>
                {i18n.t('from')} {'System'} {i18n.t('add')}
              </div> :
              <div>
                {i18n.t('from')} {OperatorAccount || 'System'} {val === PROMOTION_REBATE_STATE.reject ? i18n.t('reject') : i18n.t('distribution')}
              </div>
          }
        </>
      )
    },
  ].map((item, i) => ({ ...item, key: i, show: true })));

  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked;
        return true
      }
    })
    setColumns([...columns]);
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: 0,
        }}>
          {/* 搜尋第一排 */}
          <Row gutter={10} align="middle">
            <Col className="w-12">
              <Form.Item name="order">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="memberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="LevelId">
                <MemberLevelSelect name="LevelId" form={form} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('all')}` },
                    ...common.enumToOptions(PROMOTION_REBATE_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="recommendedMembers">
                <Input placeholder={`${i18n.t('recommendedMembers')}`} />
              </Form.Item>
            </Col>
          </Row>
          {/* 搜尋第二排 */}
          <Row gutter={10} className="mt-1">
            <Col className="w-12">
              <Form.Item name="agentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="masterAgent">
                <Input placeholder={`${i18n.t('masterAgent')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="TimeMode">
                <Select
                  className="w-full"
                  onChange={handleDateType}
                  options={[
                    { value: 0, label: `${i18n.t('createTime')}` },
                    { value: 1, label: `${i18n.t('distributionTime')}` },
                    { value: 2, label: `${i18n.t('takeTime')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={181} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
            <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
          </Row>
          {/* 總計 */}
          <Row gutter={[16, 16]} className="mt-1">
            <Col span={24}>

              <Table
                loading={isValidating}
                size="small"
                dataSource={[{
                  TotalRecord: rebateList?.TotalRecord || 0,
                  TotalCommission: rebateList?.TotalCommission || 0
                }]}
                columns={[
                  {
                    dataIndex: 'TotalRecord',
                    title: i18n.t('totalCount'),
                    align: "right",
                    width: '50%'
                  },
                  {
                    dataIndex: 'TotalCommission',
                    title: i18n.t('totalRebate'),
                    align: "right",
                    width: '50%',
                    render: (val) => common.toFormatNumber(val)
                  },
                ]}
                pagination={false}
              />

            </Col>
          </Row>
          {/* 全部派發 顯示設定 */}
          <Row gutter={[16, 16]} className="mt-1" justify="space-between">
            {/* 全部派發 */}
            <Col>
              {
                $p('30402') &&
                <Row align="middle" gutter={[12, 12]}>
                  <Col>
                    <Button
                      type="primary" ghost danger
                      disabled={selectedRowKeys.length === 0}
                      loading={allReject}
                      onClick={() => setIsOpenBatchRejectModal(true)}
                    >{i18n.t('batchReject')}</Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      loading={allPass}
                      onClick={() => setIsModalOpenAllpass(true)}
                    >{i18n.t('distributeAll')}
                    </Button>
                  </Col>
                </Row>
              }
            </Col>
            {/* 顯示設定 */}
            <Col>
              <Popover
                placement="bottomRight"
                trigger="click"
                content={
                  <div>
                    {columns.map((item: any, i: number) =>
                      <div key={i}>
                        <Checkbox checked={item.show} onChange={e => updateColumnShow(item.dataIndex, e.target.checked)}>
                          {item.title}
                        </Checkbox>
                      </div>
                    )}
                  </div>
                }
              >
                <Button><DatabaseFilled /></Button>
              </Popover>
            </Col>
          </Row>

          <Table
            loading={isValidating}
            size="middle"
            className="mt-1"
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
              getCheckboxProps: (record) => ({
                disabled:
                  record.status === PROMOTION_REBATE_STATE.dispatched ||
                  record.status === PROMOTION_REBATE_STATE.received ||
                  record.status === PROMOTION_REBATE_STATE.reject
              })
            }}
            dataSource={rebateList?.Data?.map((item: any) => ({ key: item.Id, ...item })) || []}
            columns={columns.filter((item: any) => item.show) as any}
            pagination={false}
          />
          <LayoutPagination total={rebateList && rebateList.TotalRecord} setPage={setPage} page={page} />

        </Form>
      </Content>

      <PopupAllpassPromotionRebate isOpen={isModalOpenAllpass} close={() => setIsModalOpenAllpass(false)} mutate={mutate} setAllPass={setAllPass} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
      <InquiryWindow isOpen={isOpenBatchRejectModal} close={() => setIsOpenBatchRejectModal(false)}
        msg={`${i18n.t('confirmBatchRejection')} ${selectedRowKeys.length} ${i18n.t('dataEntries')}`}
        action={handleAllReject} />
      {/* <PopupAddPromotionRebate isOpen={isModalOpenAdd} close={() => setIsModalOpenAdd(false)} />
      <PopupAuditPromotionRebate isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} />
      <PopupDetailPromotionRebate isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} /> */}
    </div>
  );
};

export default PageMain;